// !: INIT Component – Navbar

.navbar {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;

    max-width: 1920px;
    height: $navbar-height-mobile;
    margin-right: auto;
    margin-left: auto;
    // padding: $navbar-padding-x $navbar-padding-y;
    padding: 0;
    // border-bottom: 1px solid rgba(black, 0.2);

    background-color: transparent;

    @include media-breakpoint-up(lg) {
        height: $navbar-height-desktop;

        background: $navy;

        &.navbar--image {
            background: unset;
        }
    }

    .container-fluid {
        margin-bottom: auto;
        padding: 0 0 0 20px;

        @include media-breakpoint-up(xl) {
            padding: 0 0 0 15px;
        }
        @include media-breakpoint-up(xxxl) {
            padding: 0 45px 0 5.7299%;
        }
        @include media-breakpoint-between(xxl, xxxl) {
            padding-left: 3%;
        }

        //same also on modals
    }

    .navbar-collapse {
        @include media-breakpoint-up(xxl) {
            align-items: flex-start;
        }

        // @include media-breakpoint-down(lg) {
        //     padding: $navbar-padding-x $navbar-padding-y;
        // }
    }

    .nav-item {
        &--overview {
            h2 {
                display: none;

                color: white;
            }

            .nav-link {
                font-size: 16px;
                font-weight: 600;

                text-decoration: underline;

                border-bottom: 0;

                text-decoration-thickness: 2px;
                text-underline-offset: 2px;
            }
        }

        .dropdown-menu {
            padding-left: 20px;

            &.show {
                background: darken($navy, 5%);
            }

            .nav-link {
                font-weight: 400;

                margin-top: 0;
                padding-top: 10px;
                padding-bottom: 10px;

                background: transparent;
            }

            .dropdown-menu {
                padding-left: 40px;
            }
        }

        .nav-item {
            &.dropdown {
                position: relative;

                .btn-transparent {
                    position: absolute;
                    top: 6px;
                    right: 0;
                }
            }

            .nav-link {
                font-size: 20px;

                width: 100%;
                padding-right: 40px;
            }
        }

        &.is-active {
            .nav-link {
                @include media-breakpoint-up(lg) {
                    color: theme-color("primary");
                }
            }
        }

        &.dropdown {
            position: relative;

            .dropdown-toggle {
                position: absolute;
                top: 26px;
                right: 0;

                display: flex;

                width: 44px;
                height: 37px;

                align-items: center;
                justify-content: center;

                &:after {
                    display: none;
                }

                svg {
                    transition: all 0.2s linear;
                }

                &.show {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .nav-link {
        font-size: 16px;
        font-weight: 450;
        line-height: 23px;

        display: block;

        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        color: white;

        @include media-breakpoint-up(lg) {
            font-size: 15px;

            // background: $navy;
        }
        @include media-breakpoint-up(xl) {
            font-size: 14px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 16px;

            background-color: $navy;

            &:hover {
                text-decoration: underline;
                // color: $primary;

                color: white;

                text-decoration-thickness: 2px;
                text-underline-offset: 3px;
                text-decoration-color: $gold;
            }
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 18px;
        }
        @include media-breakpoint-down(xxl) {
            font-size: 27px;
            font-weight: 500;
            line-height: 140%;

            margin-top: 26px;
            padding-bottom: 16px;

            text-align: left;
            letter-spacing: -0.01em;
            text-transform: none;

            border-bottom: 1px solid #D1D1D1;

            &:hover {
                color: $gold;
            }
        }
    }

    .navbar-brand {
        margin-top: 30px;
        padding: 0;

        @include media-breakpoint-down(md) {
            margin-top: 15px;
            margin-bottom: auto;
        }

        align-self: flex-start;
    }

    .navbar-brand__main {
        margin-right: 20px;

        @include media-breakpoint-up(lg) {
            margin-right: 10px;
        }
        @include media-breakpoint-up(xl) {
            margin-right: 30px;
        }
        @include media-breakpoint-up(xxl) {
            margin-right: 50px;
        }
        @include media-breakpoint-up(xxxl) {
            margin-right: 62px;
        }

        img,
        svg {
            width: 100%;
            max-width: 165px;

            @include media-breakpoint-up(lg) {
                max-width: 246.4px;
            }
        }
    }

    &__cta {
        display: flex;

        @include media-breakpoint-down(xl) {
            flex-direction: column;

            align-items: center;
            // width: 56px;
            // margin-right: 20px;
        }

        order: 10;
    }

    &__button {
        z-index: $zindex-fixed;

        display: flex;
        flex-direction: column;

        width: 100px;
        height: $navbar-height-mobile;
        margin-top: - $navbar-padding-y;
        margin-bottom: - $navbar-padding-y;
        padding: 0;

        text-decoration: none;

        border: none;
        background: none;

        @include media-breakpoint-down(xl) {
            width: 56px;
            height: auto;
            margin-top: 24px;
            margin-bottom: 0;
        }

        align-items: center;
        justify-content: center;

        &--ico {
            display: block;

            height: 31px;

            color: white;

            @include media-breakpoint-down(lg) {
                height: 27px;
            }

            @at-root .navbar--image #{&} {
                color: $blue;
            }

            .template--cao & {
                color: white;
            }
        }

        &--courses {
            order: 8;
        }

        &--search {
            order: 9;
        }
    }

    .hamburger,
    &__button {
        &:hover {
            .hamburger-label {
                transform: translateY(-1px);
            }
        }
    }
}
