// !: INIT Component – Offcanvas
.menu-opened {
    overflow: hidden;

    @include media-breakpoint-down(xxl) {
        .navbar__button--search,
        .navbar__button--courses {
            display: none;
        }
        main {
            &:before {
                position: fixed;
                z-index: 999;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                content: "";

                background: linear-gradient(90.03deg, rgba(0, 0, 0, 0.664) -0.29%, rgba(0, 0, 0, 0) 82.54%);
            }
        }
    }
}
@include media-breakpoint-down(xxl) {
    .offcanvas-header {
        width: 100%;
        padding: 0;

        align-items: flex-start;
        .offcanvas-title {
            margin-top: 32px;
            margin-left: 23px;
            img,
            svg {
                max-width: 165px;
            }
        }
        .btn-close {
            width: 100px;
            height: 147px;
            margin: 0;

            opacity: 1;
            color: white;
            border-radius: 0;
            background: $blue;
            .hamburger-label {
                display: block;
                .show & {
                    color: white!important;
                    background: $blue;
                }
            }
        }
    }
    .offcanvas-collapse {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;

        overflow-y: auto;

        width: 450px;
        // transition: 0.3s ease-out;
        // transition-property: transform, visibility, opacity;
        // transform: translate(0, -50px);
        // opacity: 0;

        border-left: 1px solid rgba(black, 0.2);
        background-color: $navy;
    }
    .offcanvas-body {
        position: relative;

        display: flex;
        flex-direction: column;

        width: 100%;
        padding-top: 47px;
        padding-right: 23px;
        // padding-bottom: 40px;
        padding-bottom: 0;
        padding-left: 23px;
        .navbar-nav {
            width: 100%;
        }
    }
    .bottom-element {
        display: block;

        width: 100%;
        margin-top: auto;
        &__item {
            position: relative;
            z-index: -1;
            right: 0;
            bottom: 0;
            left: 0;

            display: block;
            overflow: hidden;

            height: 140px;
            margin-top: auto;
            margin-right: -23px;
            margin-left: -23px;

            // transform: translateY(100px);
            &:before {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;

                display: block;

                width: 100%;
                height: 150px;

                content: " ";
                transform: skew(0, -13deg) translateY(100px);

                background: $gold;
            }
        }
    }
    .menuBoxed {
        display: block;

        margin-top: 50px;
        margin-bottom: -100px;
    }
    .dropdown-menu {
        padding: 0;

        border: none;
        border-radius: 0;
        background: $navy;
        .dropdown-item {
            font-size: 16px;

            color: white;
            background: unset;
            &:hover {
                text-decoration: underline;

                text-decoration-thickness: 2px;
                text-underline-offset: 3px;
                text-decoration-color: $gold;
            }
        }
    }
}
