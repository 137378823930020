.section--component-16 {
    position: relative;
    z-index: 2;
}

.component-16 {
    padding: 100px 0;

    // .input-group {
    //     position: relative;

    //     margin-top: 40px;
    //     margin-bottom: 68px;

    //     @include media-breakpoint-up(xl) {
    //         margin-top: 50px;
    //         margin-bottom: 97px;
    //     }
    //     .input-group-text {
    //         position: absolute;
    //         z-index: 10;
    //         top: 0;
    //         bottom: 0;
    //         left: 0;

    //         width: 64px;

    //         border: none;
    //         background: transparent;
    //         svg {
    //             margin: auto;
    //         }
    //     }
    //     .form-control {
    //         font-size: 18px;
    //         font-weight: 500;
    //         line-height: 23px;

    //         padding: 22px 24px 20px 62px;

    //         letter-spacing: -0.01em;

    //         color: $navy;
    //         border: 1px solid $blue;
    //         border-radius: 0;

    //         @include media-breakpoint-up(xl) {
    //             font-size: 27px;
    //             line-height: 140%;

    //             padding: 23px 24px 23px 64px;
    //         }
    //         &::placeholder {
    //             color: $navy;
    //         }
    //     }
    // }
    &__box {
        display: flex;
        flex-direction: column;

        margin-bottom: 24px;

        text-decoration: none;

        background: white;

        flex: 1;
        @extend .mtu-shadow-soft;
    }
    &__item {
        margin: auto;
        padding: 46px;

        text-align: center;

        color: white;

        @include media-breakpoint-up(lg) {
            padding: 73px 32px;
        }
        h2 {
            font-size: 28px;
            font-weight: 600;
            line-height: 120%;

            margin-bottom: 14px;

            letter-spacing: -0.01em;

            color: inherit;

            @include media-breakpoint-up(lg) {
                font-size: 27px;
                font-weight: 500;
                line-height: 140%;

                margin-bottom: 66px;
            }
        }
    }
    &__cta {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }
}
