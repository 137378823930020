// Override built-in Boostrap variables
// ::: Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px,
);

// ::: Max-widths containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1594px,
);

@function get-breakpoints($key: "md") {
    @return map-get($container-max-widths, $key);
}


// ::: Theme Colors
$secondary: #D1D1D1;
$blue: #00A2E1;
$blue-a11y: #029fdc;
$navy: #003E7D;
$red: #D90725;
$gold: #F0AF00;
$dark-grey: #6A6A6A;
$grey-mid: #3D4C57;
$grey-mid-lighter: #657582;
$grey-light: #F7F7F7;
$grey-lighter: #F2F2F2;
$theme-colors: (
    "primary": $navy,
    "secondary": $secondary,
    "blue": $blue,
    "red": $red,
    "gold": $gold,
    "dark-grey": $dark-grey,
    "grey-mid": $grey-mid,
    "grey-mid-lighter": $grey-mid-lighter,
    "grey-light": $grey-light,
    "grey-lighter": $grey-lighter,

);
// ::: Theme Colors Remove from map
$theme-colors: map-remove($theme-colors, "info", "light", "dark", "warning" );

// // ::: Bootstrap Variables Overwrite
$font-family-base: $font-base;

// // ::: Bootstrap Variables
// $font-family-base: $font-base;
// // $font-size-base:2rem;
// // $font-size-root:16px;

// // ::: Bootstrap RFS
// $rfs-base-value: 1.25rem;
// $rfs-breakpoint: 1600px;

// // ::: Bootstrap Headings
$h1-font-size: 67px;
$h2-font-size: 53px;
$h3-font-size: 42px;
$h4-font-size: 34px;
$h5-font-size: 27px;
$h6-font-size: 18px;
$headings-line-height: 130%;
$headings-font-weight: 700;
$headings-font-family: $font-base;
$headings-margin-bottom: 22px;
$headings-color: $navy;

// // ::: Bootstrap Buttons
$btn-padding-y: 16px;
$btn-padding-x: 24px;
$btn-line-height: 25.2px;
$btn-font-size: 18px;
$btn-font-weight: 500;
// $btn-font-family: $font-base;
$btn-border-radius: 0;

// // ::: Bootstrap Dropdown Menu
// $dropdown-border-radius: 0;

// // ::: Bootstrap Link
// $link-color: #0075AC;
// $link-decoration: none;
// $link-hover-decoration: none;

// // ::: Modal

// $modal-md: 1000px;
// $modal-lg: 1200px;
// $modal-content-border-radius: 0;
// $modal-inner-padding: 0;

// // ::: Breadcrumb
// $breadcrumb-active-color: $grey-mid;
// $breadcrumb-divider-color: $grey-mid;

// !!! nav link
$nav-link-padding-y: 0;
$form-check-input-width: 1.25em;
$form-check-input-checked-bg-color: #00A2E1;
$form-check-input-border-radius: 0;
$form-check-input-border: solid 1px #00A2E1;
$form-check-input-checked-border-color: #003E7D;

$link-color : $blue-a11y;