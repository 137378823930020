#SearchModal {
    .navbar-brand__main {
        margin-right: 20px;

        @include media-breakpoint-up(lg) {
            margin-right: 10px;
        }
        @include media-breakpoint-up(xl) {
            margin-right: 30px;
        }
        @include media-breakpoint-up(xxl) {
            margin-right: 50px;
        }
        @include media-breakpoint-up(xxxl) {
            margin-right: 62px;
        }

        img,
        svg {
            width: 100%;
            max-width: 165px;

            @include media-breakpoint-up(lg) {
                max-width: 246.4px;
            }
        }
    }

    .close {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;

        position: absolute;
        top: 0;
        right: 0;

        width: 100px;
        height: 147px;
        margin-left: auto;

        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        color: white;
        border: none;
        background: $blue;

        img,
        svg {
            margin: 20px 10px;
        }
    }

    .input-group {
        .form-control {
            border-radius: 0;
        }
    }

    .modal-dialog {
        max-width: 100%;
        height: 100%;
        margin: 0;
    }

    .modal-content {
        min-height: 100%;

        color: white;
        border: none;
        border-radius: 0;
        background: $navy;
    }

    .modal-header {
        padding: 0;

        border: none;
    }

    .modal-body {
        display: flex;
        flex-direction: column;

        padding: 0;
    }

    .title {
        font-size: 46px;
        font-weight: 900;

        margin-bottom: 20px;

        border-top: solid 1px rgba(white, 0.2);
    }

    .subtitle {
        font-size: 30px;
        font-weight: bold;

        margin-bottom: 20px;

        border-top: solid 1px rgba(white, 0.2);
    }

    .search-form-main {
        margin-bottom: 30px;
    }

    .search-item {
        a {
            text-decoration: none;

            color: white;

            &:hover {
                text-decoration: underline;

                text-decoration-thickness: 2px;
                text-underline-offset: 3px;
                text-decoration-color: $gold;
            }
        }
    }

    .bg-white {
        color: $blue;
    }

    .corner-right {
        margin-top: auto;

        text-align: center;

        svg {
            display: block;

            max-width: 100%;
            margin-left: auto;
        }
    }
}

.menuModal {
    .close {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;

        width: 100px;
        height: 147px;
        margin-left: auto;

        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;

        color: white;
        border: none;
        background: $blue;

        img,
        svg {
            margin: 20px 10px;
        }
    }

    .modal-dialog {
        max-width: 100%;
        // height: 100%;
        min-height: 100vh;
        margin: 0;
    }

    .modal-content {
        height: 100%;
        min-height: 100vh;

        color: white;
        border: none;
        border-radius: 0;
        background: $navy;
    }

    .modal-body {
        display: flex;
        flex-direction: column;

        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
    }

    .modal-header {
        padding: 0 0 0 20px;

        border: none;

        @include media-breakpoint-up(xl) {
            padding: 0 0 0 15px;
        }
        @include media-breakpoint-up(xxxl) {
            padding: 0 0 0 5.7299%;
        }
        @include media-breakpoint-between(xxl, xxxl) {
            padding-left: 3%;
        }
    }

    .corner-right {
        margin-top: auto;

        text-align: center;

        svg {
            display: block;

            max-width: 100%;
            margin-left: auto;
        }
    }

    .menu-item {
        width: 268px;
        max-width: 100%;

        border-top: solid 1px rgba(white, 0.2);

        .title {
            font-size: 27px;
            font-weight: 500;
            line-height: 140%;

            display: block;

            margin-top: 16px;
            margin-bottom: 16px;

            text-decoration: none;
            letter-spacing: -0.01em;

            color: white;
            background: $navy;
        }

        .drop-menu {
            margin-bottom: 50px;
            padding-left: 0;

            list-style: none;

            li {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;

                margin-bottom: 8px;

                color: white;
                background: $navy;

                a {
                    text-decoration: none;

                    color: white;

                    &:hover {
                        text-decoration: underline;

                        text-decoration-thickness: 2px;
                        text-underline-offset: 3px;
                        text-decoration-color: $gold;
                    }
                }
            }
        }

        &__wrapper {
            display: flex;

            flex-wrap: wrap;
        }
    }
}

.menuBoxed {
    text-decoration: none;

    &__img--wrap {
        img {
            width: 100%;
            max-width: 100%;
        }
    }

    &__content--wrap {
        padding: 32px;

        color: $navy;
        background: white;

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;

            margin: 0;
            /* or 25px */

            letter-spacing: -0.01em;
        }
    }
}
