/* ===============================
=            Choices            =
=============================== */
.choices {
    font-size: 16px;

    position: relative;

    overflow: hidden;

    margin-bottom: 24px;
}
.choices:focus {
    outline: none;
}
.choices:last-child {
    margin-bottom: 0;
}
.choices.is-open {
    overflow: initial;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
    cursor: not-allowed;
    -webkit-user-select: none;
    user-select: none;

    background-color: #EAEAEA;
}
.choices.is-disabled .choices__item {
    cursor: not-allowed;
}
.choices [hidden] {
    display: none !important;
}

.choices[data-type*="select-one"] {
    cursor: pointer;
}
.choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 7.5px;
}
.choices[data-type*="select-one"] .choices__input {
    display: block;

    width: 100%;
    margin: 0;
    padding: 10px;

    border-bottom: 1px solid #DDDDDD;
    background-color: #FFFFFF;
}
.choices[data-type*="select-one"] .choices__button {
    position: absolute;
    top: 50%;
    right: 0;

    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-right: 25px;
    padding: 0;

    opacity: 0.25;
    border-radius: 10em;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
    background-size: 8px;
}
.choices[data-type*="select-one"] .choices__button:hover,
.choices[data-type*="select-one"] .choices__button:focus {
    opacity: 1;
}
.choices[data-type*="select-one"] .choices__button:focus {
    box-shadow: 0 0 0 2px #00BCD4;
}
.choices[data-type*="select-one"]
    .choices__item[data-value=""]
    .choices__button {
    display: none;
}
.choices[data-type*="select-one"]::after {
    position: absolute;
    top: 50%;
    right: 11.5px;

    width: 0;
    height: 0;
    margin-top: -2.5px;

    content: "";
    pointer-events: none;

    border-width: 5px;
    border-style: solid;
    border-color: #333333 transparent transparent transparent;
}
.choices[data-type*="select-one"].is-open::after {
    margin-top: -7.5px;

    border-color: transparent transparent #333333 transparent;
}
.choices[data-type*="select-one"][dir="rtl"]::after {
    right: auto;
    left: 11.5px;
}
.choices[data-type*="select-one"][dir="rtl"] .choices__button {
    right: auto;
    left: 0;

    margin-right: 0;
    margin-left: 25px;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
    cursor: text;
}
.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
    line-height: 1;

    position: relative;

    display: inline-block;

    width: 8px;
    margin-top: 0;
    margin-right: -4px;
    margin-bottom: 0;
    margin-left: 8px;
    padding-left: 16px;

    opacity: 0.75;
    border-left: 1px solid #008FA1;
    border-radius: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
    background-size: 8px;
}
.choices[data-type*="select-multiple"] .choices__button:hover,
.choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
    opacity: 1;
}

.choices__inner {
    font-size: 14px;

    display: inline-block;
    overflow: hidden;

    width: 100%;
    min-height: 44px;
    padding: 7.5px 7.5px 3.75px;

    vertical-align: top;

    border: 1px solid #DDDDDD;
    border-radius: 2.5px;
    background-color: #F9F9F9;
}
.is-focused .choices__inner,
.is-open .choices__inner {
    border-color: #B7B7B7;
}
.is-open .choices__inner {
    border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
    border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
    margin: 0;
    padding-left: 0;

    list-style: none;
}

.choices__list--single {
    display: inline-block;

    width: 100%;
    padding: 4px 16px 4px 4px;
}
[dir="rtl"] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px;
}
.choices__list--single .choices__item {
    width: 100%;
}

.choices__list--multiple {
    display: inline;
}
.choices__list--multiple .choices__item {
    font-size: 12px;
    font-weight: 500;

    display: inline-block;

    box-sizing: border-box;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    padding: 4px 10px;

    vertical-align: middle;
    word-break: break-all;

    color: #FFFFFF;
    border: 1px solid #00A5BB;
    border-radius: 20px;
    background-color: #00BCD4;
}
.choices__list--multiple .choices__item[data-deletable] {
    padding-right: 5px;
}
[dir="rtl"] .choices__list--multiple .choices__item {
    margin-right: 0;
    margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
    border: 1px solid #008FA1;
    background-color: #00A5BB;
}
.is-disabled .choices__list--multiple .choices__item {
    border: 1px solid #919191;
    background-color: #AAAAAA;
}

.choices__list--dropdown {
    position: absolute;
    z-index: 10;
    top: 100%;

    visibility: hidden;
    overflow: hidden;

    width: 100%;
    margin-top: -1px;

    word-break: break-all;

    border: 1px solid #DDDDDD;
    border-bottom-right-radius: 2.5px;
    border-bottom-left-radius: 2.5px;
    background-color: #FFFFFF;

    will-change: visibility;
}
.choices__list--dropdown.is-active {
    visibility: visible;
}
.is-open .choices__list--dropdown {
    border-color: #B7B7B7;
}
.is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;

    margin-top: 0;
    margin-bottom: -1px;

    border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list {
    position: relative;

    overflow: auto;

    max-height: 300px;

    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
    font-size: 14px;

    position: relative;

    padding: 10px;
}
[dir="rtl"] .choices__list--dropdown .choices__item {
    text-align: right;
}
@media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable {
        padding-right: 100px;
    }
    .choices__list--dropdown .choices__item--selectable::after {
        font-size: 12px;

        position: absolute;
        top: 50%;
        right: 10px;

        content: attr(data-select-text);
        transform: translateY(-50%);

        opacity: 0;
    }
    [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
        padding-right: 10px;
        padding-left: 100px;

        text-align: right;
    }
    [dir="rtl"] .choices__list--dropdown .choices__item--selectable::after {
        right: auto;
        left: 10px;
    }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: #F2F2F2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after {
    opacity: 0.5;
}

.choices__item {
    cursor: default;
}

.choices__item--selectable {
    cursor: pointer;
}

.choices__item--disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    user-select: none;

    opacity: 0.5;
}

.choices__heading {
    font-size: 12px;
    font-weight: 600;

    padding: 10px;

    color: gray;
    border-bottom: 1px solid #F7F7F7;
}

.choices__button {
    cursor: pointer;
    text-indent: -9999px;

    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;

    -webkit-appearance: none;
    appearance: none;
}
.choices__button:focus {
    outline: none;
}

.choices__input {
    font-size: 14px;

    display: inline-block;

    max-width: 100%;
    margin-bottom: 5px;
    padding: 4px 0 4px 2px;

    vertical-align: baseline;

    border: 0;
    border-radius: 0;
    background-color: #F9F9F9;
}
.choices__input:focus {
    outline: 0;
}
[dir="rtl"] .choices__input {
    padding-right: 2px;
    padding-left: 0;
}

.choices__placeholder {
    opacity: 0.5;
}

/* =====  End of Choices  ====== */
