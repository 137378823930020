.section--component-1 {
    position: relative;
    z-index: 2;

    margin-bottom: 10%;

    opacity: 1 !important;
    // background: $navy;

    @include media-breakpoint-up(xxxl) {
        margin-bottom: 13%;
    }

    transform-style: preserve-3D;

    + .section--component-20,
    + span + .section--component-20 {
        @include media-breakpoint-down(lg) {
            padding-top: 0;
        }
    }

    &.main-section {
        margin-bottom: 0;

        background: unset;

        @include media-breakpoint-up(lg) {
            min-height: 57vw;
        }
        @include media-breakpoint-up(xl) {
            min-height: 51vw;
        }
        @include media-breakpoint-up(xxxl) {
            min-height: 35vw;
            margin-bottom: 10%;
        }
    }

    &[class*="bg-"] {
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            min-height: 60vh;
        }
        @include media-breakpoint-up(xl) {
            min-height: 61vh;
        }
        @include media-breakpoint-up(xxl) {
            min-height: 67vh;
        }
        @include media-breakpoint-up(xxxl) {
            min-height: 79vh;
        }
        @include media-breakpoint-down(lg) {
            padding-bottom: 5%;
        }
        @include media-breakpoint-down(md) {
            padding-bottom: 7%;
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 10%;
        }

        @media (min-width: 1800px) {
            min-height: 730px;
        }
    }

    .rectangle {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;

        content: "";

        &__item {
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;
            overflow: hidden;

            width: 200px;
            height: 50px;

            transform: translateY(50%) translateX(-70%);

            background: rgba($blue, 0.8);

            @include media-breakpoint-up(lg) {
                width: 570px;
                height: 100px;
            }
        }

        &__red {
            right: 0;
            bottom: 0;
            left: auto;

            .rectangle__item {
                width: 50vw;
                height: 50px;

                transform: translateY(50%) translateX(-100%);

                background: rgba($red, 0.8);

                @include media-breakpoint-up(sm) {
                    width: 40vw;
                }
                @include media-breakpoint-up(md) {
                    width: 250px;
                }
                @include media-breakpoint-up(lg) {
                    width: 500px;
                    height: 160px;
                }
                @include media-breakpoint-up(xxl) {
                    width: 44vw;
                    height: 200px;
                }
            }

            @at-root .main-section#{&} {
                .rectangle__item {
                    @include media-breakpoint-up(lg) {
                        transform: translateY(10%) translateX(-100%);
                    }
                    @include media-breakpoint-up(xl) {
                        transform: translateY(0%) translateX(-100%);
                    }
                    @include media-breakpoint-up(xxl) {
                        transform: translateY(20%) translateX(-100%);
                    }
                    @include media-breakpoint-up(xxxl) {
                        bottom: 160px;
                    }
                }
            }
        }
    }
}

.component-1 {
    transform: skew(0deg, -10deg) translateY(-200px);

    background: $navy;

    transform-style: preserve-3D;

    .container {
        position: relative;
    }

    .main-section & {
        position: relative;

        transform: skew(0deg, -13deg) translateY(-200px);

        color: white;
        background: unset;
    }

    &:before {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: calc(100% - 96px);
        // width: 100%;

        overflow: hidden;

        width: 96px;
        height: 200%;

        content: "";
        // transform: translateZ(-1px);

        background: white;

        @include media-breakpoint-up(xl) {
            left: 70%;

            width: 30%;
        }

        .template--cao & {
            display: none;
        }
    }

    &:after {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 200%;

        content: "";

        background: $navy;
    }

    &__block {
        position: relative;
        z-index: 4;

        margin-top: 200px;

        transform: skew(0deg, 10deg) translateY(0);

        .main-section & {
            transform: skew(0deg, 13deg) translateY(0);
        }
    }

    &__wrapper {
        position: relative;
        z-index: 2;

        // background: $navy;
    }

    &__content {
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;

        max-width: calc(100% - 80px);
        padding-top: 140px;
        padding-bottom: 110px;

        @include media-breakpoint-up(lg) {
            max-width: 700px;
            min-height: 500px;
            padding-top: 180px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 780px;
        }
        @include media-breakpoint-up(xxl) {
            max-width: 837px;
        }
        @include media-breakpoint-down(md) {
            min-height: 260px;
            padding-bottom: 60px;
        }

        align-items: flex-start;
        flex-wrap: wrap;

        .template--cao & {
            @include media-breakpoint-up(lg) {
                padding-bottom: 50px;
            }
        }
    }

    &__subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 8px;
        // text-transform: uppercase;

        color: white;
        background: $navy;

        order: -1;
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        position: relative;
        z-index: 1;

        transform: translateZ(-1px);

        color: white;
        background: $navy;

        @include media-breakpoint-up(lg) {
            font-size: 67px;
            font-weight: 700;
        }

        transform-style: preserve-3D;

        .main-section & {
            width: 686px;
            max-width: 100%;

            letter-spacing: -0.01em;

            @include media-breakpoint-up(xxxl) {
                font-size: 90px;
                font-weight: 700;
                line-height: 120%;
            }
        }
    }

    &__paragraph {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        position: relative;
        z-index: 1;

        width: 514px;
        max-width: 100%;
        margin-bottom: 40px;

        letter-spacing: -0.01em;

        color: white;
        background: $navy;

        @include media-breakpoint-down(lg) {
            font-size: 16px;
            font-weight: 400;

            margin-bottom: 32px;
        }

        .template--cao & {
            @include media-breakpoint-only(xl) {
                width: 388px;
            }
            @include media-breakpoint-only(lg) {
                width: 344px;
            }
        }
    }

    &__img {
        position: relative;
        z-index: 3;
        right: 0;

        overflow: hidden;

        width: 100%;
        // min-width: 528px;
        margin-left: auto;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: $navbar-height-desktop;
            right: 0;

            width: 70%;
            margin-top: 300px;

            .main-section & {
                width: 62%;
            }
        }

        // transform: skew(0deg, -10deg) translateY(20%);
        .main-section & {
            overflow: visible;
        }

        img {
            max-width: 100%;
        }

        &--mobile {
            margin-bottom: -340px;

            transform: skew(0deg, -10deg) translateY(-200px);

            @include media-breakpoint-down(sm) {
                margin-bottom: -280px;
            }

            .main-section & {
                transform: skew(0deg, -13deg) translateY(-200px);
            }
        }
    }

    &__imgBlock {
        position: relative;

        overflow: hidden;

        margin-bottom: 0;

        transform: skew(0deg, 10deg) translateY(-20%);

        @include media-breakpoint-down(lg) {
            &::after {
                display: block;

                padding-bottom: 50%;

                content: " ";

                .main-section & {
                    margin-top: 24px;
                    padding-bottom: 74%;
                }
            }

            img {
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;

                width: 100%;
                height: 100%;

                transform: translateY(-50%);

                object-fit: cover;
            }
        }
        @include media-breakpoint-down(md) {
            &:after {
                padding-bottom: 60%;

                .main-section & {
                    margin-top: 15px;
                    padding-bottom: 74%;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            &:after {
                padding-bottom: 70%;

                .main-section & {
                    margin-top: 10px;
                    padding-bottom: 74%;
                }
            }
        }

        .main-section & {
            transform: skew(0deg, 13deg) translateY(-20%);
        }

        .main-section & {
            overflow: visible;
        }
    }
}
