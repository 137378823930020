.section--component-8 {
    position: relative;
    z-index: 1;

    margin-bottom: -1px;
    padding-top: 10%;
    padding-bottom: 10%;

    transform: skew(0deg, -10deg);

    + .section--component-23,
    + span + .section--component-23 {
        .component-23 {
            padding-top: 50px;
        }
    }
}

.component-8 {
    position: relative;

    overflow: hidden;

    transform: skew(0deg, 10deg);

    color: $navy;
    background-color: inherit;

    .container {
        background-color: inherit;
    }

    &__wrapper {
        position: relative;
        z-index: 1;

        background-color: inherit;
    }

    &__content {
        position: relative;
        z-index: 101;

        background-color: inherit;
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        margin-bottom: 16px;
        padding-top: 10px;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        max-width: 837px;
        margin-bottom: 24px;

        letter-spacing: -0.01em;
    }

    .btn-primary {
        margin-bottom: 24px;
    }

    &__carousel {
        background-color: inherit;

        * {
            background-color: inherit;
        }
    }
}

.events {
    &__carousel {
        background-color: inherit;

        @include media-breakpoint-down(md) {
            margin-right: 50px;
        }



        &--nav {
            position: relative;
            z-index: 100;

            display: flex;

            height: 100%;

            background-color: inherit;

            &:before {
                position: absolute;
                z-index: -1;
                top: -100px;
                right: 0;
                bottom: -1px;

                width: 100vw;
                height: calc(100% + 101px);

                content: "";

                background: inherit;
            }
        }

        &--arrows {
            display: flex;

            padding-bottom: 100px;

            color: $navy;

            align-items: flex-end;
        }

        &--next {
            margin-left: 65px;
        }

        &--prev {
            margin-left: 10px;
        }

        .swiper-slide {
            height: auto;
        }

        .content {
            display: flex;

            height: 100%;
        }

        .slide {
            &__item {
                display: flex;
                flex-direction: column;

                padding: 32px;

                text-decoration: none;

                color: $navy;
                background: $gold;

                &--red {
                    min-height: 305px;

                    color: white;
                    background: $red;
                }

                &--date {
                    font-size: 16px;
                    font-weight: 400;

                    display: block;

                    margin-bottom: 20px;
                }

                &--text {
                    display: block;

                    h3 {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 140%;

                        margin-bottom: 20px;
                        // color: $navy;

                        color: inherit;

                        @include media-breakpoint-up(lg) {
                            font-size: 22px;
                        }
                        @include media-breakpoint-up(xxl) {
                            font-size: 27px;
                        }
                    }
                }

                &--location {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }

        &--dots {
            margin: 5px 0 0;
            padding-left: 0;
        }
    }
}
