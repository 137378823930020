.section--component-21 {
    position: relative;
    z-index: 0;

    margin-bottom: -1px;
}

.component-21 {
    padding-top: 10%;
    padding-bottom: 10%;
    .container {
        @include media-breakpoint-up(xxl) {
            max-width: 1274px;
        }
    }
    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        max-width: 837px;
        margin-bottom: 16px;
        padding-top: 10px;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }
        &.title-small {
            @include media-breakpoint-up(lg) {
                font-size: 42px;
                font-weight: 700;
                line-height: 120%;
            }
        }
    }
    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;

        margin-bottom: 16px;

        color: $grey-mid;
    }
}
