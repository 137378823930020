.section--component-19 {
    position: relative;
    z-index: 1;

    transform: skew(0deg, -10deg);

    background: $blue;
}
.component-19 {
    position: relative;

    margin-bottom: -200px;
    padding-top: 10%;
    padding-bottom: 200px;

    transform: skew(0deg, 10deg);


    // &:before {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: auto;

    //     display: block;

    //     width: 1920px;
    //     height: 100%;

    //     content: "";


    //     background-image: url("../images/content/component-9/fig.png");
    // }
    // &:after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: auto;

    //     display: block;

    //     width: 1920px;
    //     height: 100%;

    //     content: "";

    //     background-color: $blue;

    //     clip-path: polygon(0 77%, 100% 0, 100% 100%, 0% 100%);
    // }
    &__wrapper {
        position: relative;
        z-index: 1;
    }
    &__content {
        color: white;
    }
    .section__title {
        font-size: 42px;
        line-height: 120%;

        margin-bottom: 24px;
        padding-top: 100px;

        text-align: center;

        color: inherit;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 30px;

        text-align: center;
    }
    &__cta {
        display: flex;

        margin-right: -10px;
        margin-bottom: 165px;
        margin-left: -10px;

        justify-content: center;
    }
    &__ctaItem {
        margin-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
    }
}
