.section--component-11 {
    position: relative;
    z-index: 2;
}

.component-11 {
    padding: 100px 0;

    .row {
        @include media-breakpoint-up(xl) {
            margin-right: -16px;
            margin-left: -16px;

            .dynamic-col {
                padding-right: 16px;
                padding-left: 16px;
            }
        }
    }


    .input-group {
        position: relative;

        margin-top: 40px;
        margin-bottom: 68px;

        @include media-breakpoint-up(xl) {
            margin-top: 50px;
            margin-bottom: 97px;
        }

        .input-group-text {
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            left: 0;

            width: 64px;

            border: none;
            background: transparent;

            svg {
                margin: auto;
            }
        }

        .form-control {
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;

            padding: 22px 24px 20px 62px;

            letter-spacing: -0.01em;

            color: $navy;
            border: 1px solid $blue;
            border-radius: 0;

            @include media-breakpoint-up(xl) {
                font-size: 27px;
                line-height: 140%;

                padding: 23px 24px 23px 64px;
            }

            &::placeholder {
                color: $navy;
            }
        }
    }

    &__linkTitle {

        h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 150%;
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                font-size: 34px;
                font-weight: 600;
                line-height: 120%;

                letter-spacing: -0.01em;
            }
        }

        display: block;

        margin-bottom: 24px;

        text-decoration: none;

        color: $navy;


    }

    &__heroTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 4px;

        text-transform: uppercase;

        color: $dark-grey;
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 24px;

        color: $dark-grey;
    }

    &__courseDetails {
        display: flex;

        margin-bottom: 30px;
        padding-bottom: 29px;

        border-bottom: solid 1px rgba(black, 0.15);

        flex-wrap: wrap;
        align-items: center;

        span {
            display: flex;

            margin-right: 34px;

            color: $dark-grey;

            @include media-breakpoint-up(lg) {
                margin-right: 40px;
            }

            align-items: center;

            img,
            svg {
                margin-right: 8px;
            }
        }
    }
}