.section--component-3 {
    position: relative;
    z-index: 2;

    overflow: hidden;
    + .section--component-23,
    + span + .section--component-23 {
        margin-top: 0;
        padding-top: 0;
        .component-23 {
            padding-top: 60px;
            padding-bottom: 100px;
        }
    }
    &:not([class*="bg-"]) + .section--component-27,
    &:not([class*="bg-"]) + span + .section--component-27 {
        .component-27 {
            padding: 0;
        }
    }
    + .section--component-20,
    + span + .section--component-20 {
        margin-top: 0;
        padding-top: 0;
        &.bg-white {
            margin-top: 0;
            padding-top: 0;
            .component-20 {
                padding-top: 60px;
                padding-bottom: 60px;

                @include media-breakpoint-up(lg) {
                    padding-top: 130px;
                    padding-bottom: 130px;
                }
            }
        }
    }
}

.component-3 {
    padding: 100px 0;

    .row {
        @include media-breakpoint-up(xl) {
            margin-right: -15px;
            margin-left: -15px;

            .dynamic-col {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }


    &__content {
        display: block;



        .section__title {
            font-size: 38px;
            font-weight: 700;
            line-height: 120%;

            display: block;

            margin-bottom: 24px;

            text-align: center;

            color: $navy;

            @include media-breakpoint-up(lg) {
                font-size: 42px;
                font-weight: 700;
                line-height: 120%;

                text-align: center;
                letter-spacing: -0.01em;
            }

            .template--cao & {
                color: white;
            }
        }
        p {
            margin-bottom: 76px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            text-align: center;

            color: $navy;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }

            .template--cao & {
                color: white;
            }
        }
    }

    &__box {
        display: flex;
        flex-direction: column;

        margin-bottom: 24px;

        text-decoration: none;

        background: white;

        @extend .mtu-shadow-soft;
        flex: 1;
    }

    &__item {
        margin: auto;
        padding: 46px;

        text-align: center;

        color: white;

        @include media-breakpoint-up(lg) {
            padding: 73px 32px;
        }

        h3 {
            font-size: 28px;
            font-weight: 600;
            line-height: 120%;

            margin-bottom: 14px;

            letter-spacing: -0.01em;

            color: inherit;

            @include media-breakpoint-up(lg) {
                font-size: 27px;
                font-weight: 500;
                line-height: 140%;

                margin-bottom: 66px;
            }
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }

    &__boxContent {
        display: flex;
        flex-direction: column;

        padding: 32px;

        letter-spacing: -0.01em;

        color: $navy;
        background: white;

        align-items: flex-start;
        justify-content: center;
        flex: 1;

        h3 {
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;

            margin-bottom: 0;

            @include media-breakpoint-up(xl) {
                font-size: 16px;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 20px;
            }
            @include media-breakpoint-up(xxxl) {
                font-size: 27px;
            }
        }
    }

    &__img {
        position: relative;

        display: block;

        width: 100%;

        &:before {
            display: block;

            padding-bottom: 72%;

            content: "";
        }
    }

    &__pic {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        overflow: hidden;

        img {
            min-width: 100%;
            max-width: 100%;
            height: auto;
            min-height: 100%;

            object-fit: fill;
        }
    }

    &__hero {
        position: absolute;
        z-index: 1;
        top: 0;

        max-width: 100%;
        height: 100%;

        img,
        svg {
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    &__carousel {
        @include media-breakpoint-down(md) {
            margin-right: 26px;
        }

        .row {
            @include media-breakpoint-down(xl) {
                flex-wrap: nowrap;
            }
        }

        &--dots {
            position: relative;
            z-index: 11;

            margin: -14px 0 0;
            padding-left: 0;
        }
    }

    .dynamic-col {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(xl) {
            height: auto;
            //!: fix for swiper slide
        }
        .component-3__hero {
            display: flex;

            align-items: center;
            justify-content: center;
        }
        &:first-of-type .component-3__hero {
            // top: 0;
            // right: 0;
            // bottom: 0;
            // width: 50%;
            // padding-top: 10%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            svg,
            img {
                width: 100%;
                height: 100%;
            }
        }

        &:nth-child(2) .component-3__hero {
            // top: 0;
            // bottom: 0;
            // left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            // max-width: 60%;
            // padding: 10%;

            svg,
            img {
                width: 100%;
                height: 100%;
            }
        }

        &:nth-child(3) .component-3__hero {
            // top: 0;
            // bottom: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            // max-width: 70%;

            svg,
            img {
                width: 100%;
                height: 100%;
            }
        }

        &:nth-child(4) .component-3__hero {
            // right: 0;
            // left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            img,
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}
