.section--component-7 {
    position: relative;
    z-index: 1;

    overflow: hidden;

    margin-bottom: -1px;

    transform: skew(0deg, -10deg);

    + .section--component-19,
    + span .section--component-19 {
        z-index: 0;

        margin-top: -50px;

        @include media-breakpoint-up(xl) {
            margin-top: -170px;
        }
    }
    + .section--component-23,
    + span + .section--component-23 {
        .component-23 {
            @include media-breakpoint-up(lg) {
                padding-top: 6%;
            }
        }
    }
}

.component-7 {
    position: relative;

    padding-top: 10%;
    padding-bottom: 10%;

    transform: skew(0deg, 10deg);

    background-color: inherit;

    .container {
        background-color: inherit;
    }

    &__wrapper {
        position: relative;
        z-index: 1;

        background-color: inherit;
    }

    &__content {
        position: relative;
        z-index: 101;

        margin-bottom: 48px;

        background-color: inherit;

        .with-border & {
            padding-top: 40px;

            border-top: solid 10px $gold;
        }
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        max-width: 837px;
        margin-bottom: 16px;
        padding-top: 10px;

        color: inherit;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }

        &.title-small {
            @include media-breakpoint-up(lg) {
                font-size: 42px;
                font-weight: 700;
                line-height: 120%;
            }
        }
    }

    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        max-width: 837px;
        margin-bottom: 24px;

        letter-spacing: -0.01em;
        // color: $navy;

        color: inherit;
    }

    &__carousel {
        background-color: inherit;

        * {
            background-color: inherit;
        }
    }
}

.news {
    &__carousel {
        @include media-breakpoint-down(md) {
            margin-right: 50px;
        }

        &--nav {
            position: relative;
            z-index: 100;

            display: flex;

            height: 100%;

            background-color: inherit;

            &:before {
                position: absolute;
                z-index: -1;
                top: -100px;
                right: 0;
                bottom: -1px;

                width: 100vw;
                height: calc(100% + 101px);

                content: "";

                background: inherit;
            }
        }

        &--arrows {
            display: flex;

            padding-bottom: 100px;

            color: inherit;

            align-items: flex-end;
        }

        &--next {
            margin-left: 65px;
        }

        &--prev {
            margin-left: 10px;
        }

        .swiper-slide {
            height: auto;
        }

        .content {
            display: flex;

            width: 100%;
            height: 100%;
        }

        .slide {
            &__item {
                display: flex;
                flex-direction: column;

                width: 100%;

                text-decoration: none;

                color: $navy;
                background: white;

                &--img {
                    position: relative;

                    overflow: hidden;

                    &:before {
                        display: block;

                        padding-bottom: 73%;

                        content: "";
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        right: -50%;
                        left: -50%;

                        width: auto;
                        height: 100%;
                        margin: auto;

                        transform: translateY(-50%);
                    }
                }

                &--image {
                    img {
                        max-width: 100%;
                    }
                }

                &--date {
                    font-size: 16px;
                    font-weight: 400;

                    display: block;

                    margin-bottom: 20px;
                }

                &--content {
                    padding: 32px;
                }

                &--text {
                    display: block;
                    h3 {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 140%;

                        margin-bottom: 0;

                        @include media-breakpoint-up(lg) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        &--dots {
            margin: 5px 0 0;
            padding-left: 0;

            .swiper-pagination-bullet-active {
                background: currentColor;
            }
        }
    }
}
