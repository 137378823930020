.component-15 {
    .nav-tabs {
        &__custom {
            display: flex;

            margin-bottom: 40px;

            justify-content: flex-start;
            flex-wrap: wrap;
            .nav-item {
                + .nav-item {
                    margin-left: 2%;

                    @include media-breakpoint-up(sm) {
                        margin-left: 3%;
                    }
                    @include media-breakpoint-up(xl) {
                        margin-left: 4%;
                    }
                }
            }

            .nav-link {
                font-size: 18px;
                font-weight: 500;
                line-height: 128.91%;

                padding: 0 0 8px;

                color: $navy;
                border-top: 0;
                border-right: 0;
                border-bottom: solid 1px $blue;
                border-left: 0;
                background: none;

                @include media-breakpoint-up(xl) {
                    font-size: 27px;
                    line-height: 140%;
                }
                @media (max-width:400px) {
                    font-size: 16px;
                }
                &.active {
                    font-weight: 700;

                    border-block-width: 5px;
                }
            }
        }
    }
    .accordion-item {
        margin-bottom: 15px;
        padding: 0;

        border: solid 1px $blue;
    }
    .accordion-header {
        margin-bottom: 0;
    }
    .accordion-button {
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;

        position: relative;

        display: block;

        width: 100%;
        padding: 30px 40px 30px 30px;

        text-align: left;

        color: $navy;
        border: none;
        background: none;
        svg {
            position: absolute;
            top: 50%;
            right: 22px;

            transition: all 0.3s linear;
            transform: translateY(-50%);
        }
        &.collapsed {
            svg {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
    .accordion-body {
        font-size: 16px;
        font-weight: 500;
        line-height: 128.91%;

        padding: 0 30px 14px;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 140%;
        }
        span {
            font-weight: 700;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            letter-spacing: 0;

            color: black;
        }
    }
}
