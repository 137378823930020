.section--component-6 {
    position: relative;
    z-index: 1;

    overflow: hidden;

    margin-bottom: -1px;

    transform: skew(0deg, -10deg);

    backface-visibility: hidden;

    .template--cao & {
        overflow: visible;
    }

    + .section--component-20,
    + span + .section--component-20 {
        @include media-breakpoint-down(xl) {
            margin-top: -100px;
        }
    }
    + .section--component-23,
    + span + .section--component-23 {
        @include media-breakpoint-up(xl) {
            padding-top: 120px;
        }
    }

    // background: $red;
}

.component-6 {
    position: relative;

    overflow: hidden;

    padding-top: 10%;
    padding-bottom: 10%;

    transform: skew(0deg, 10deg) translateZ(0);

    color: white;

    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

    .template--cao & {
        margin-top: -5%;
        padding-bottom: 2%;

        color: $navy;
    }

    .bg-red & {
        color: white;
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        margin-bottom: 16px;
        padding-top: 10px;

        color: inherit;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        max-width: 837px;
        margin-bottom: 24px;

        letter-spacing: -0.01em;
    }

    &__content {
        position: relative;
        z-index: 101;

        margin-bottom: 48px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }
    }

    .btn-white {
        .template--cao & {
            color: white;
            background: $navy;
        }

        .bg-red & {
            color: $navy;
            background: white;
        }
    }
}

.life {
    &__carousel {
        @include media-breakpoint-down(md) {
            margin-right: 50px;
        }

        &--nav {
            position: relative;
            z-index: 100;

            display: flex;

            height: 100%;

            background-color: $red;

            .template--cao & {
                background: $blue;
            }

            .bg-navy & {
                background: $navy;
            }

            .bg-white & {
                background: white;
            }

            .bg-gold & {
                background: $gold;
            }

            .bg-red & {
                background: $red;
            }

            &:before {
                position: absolute;
                z-index: -1;
                top: -100px;
                right: 0;
                bottom: -1px;

                width: 100vw;
                height: calc(100% + 101px);

                content: "";

                background: inherit;
            }
        }

        &--arrows {
            display: flex;

            padding-bottom: 100px;

            color: white;

            align-items: flex-end;
        }

        &--next {
            margin-left: 65px;
        }

        &--prev {
            margin-left: 10px;
        }

        .swiper-slide {
            height: auto;
        }

        .content {
            display: flex;

            height: 100%;
        }

        .slide {
            &__item {
                display: flex;
                flex-direction: column;

                text-decoration: none;

                color: $navy;
                background: white;

                &--image {
                    img {
                        max-width: 100%;
                    }
                }

                &--img {
                    position: relative;

                    overflow: hidden;

                    &:before {
                        display: block;

                        padding-bottom: 73%;

                        content: "";
                    }

                    img {
                        position: absolute;
                        top: 50%;
                        right: -50%;
                        left: -50%;

                        width: 100%;
                        height: 100%;
                        margin: -1px auto auto;

                        transform: translateY(-50%);

                        object-fit: cover;
                    }
                }

                &--date {
                    font-size: 16px;
                    font-weight: 400;

                    display: block;

                    margin-bottom: 20px;
                }

                &--content {
                    padding: 32px;
                }

                &--text {
                    display: block;
                    h3 {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 140%;

                        margin-bottom: 0;

                        @include media-breakpoint-up(lg) {
                            font-size: 18px;
                        }
                    }
                }

                &--name {
                    font-size: 27px;
                    font-weight: 500;
                    line-height: 140%;

                    display: block;

                    width: 100%;
                    margin-bottom: 7px;

                    letter-spacing: -0.01em;
                }

                &--role {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 140%;

                    display: block;

                    width: 100%;
                    margin-bottom: 20px;

                    color: $dark-grey;
                }
            }
        }

        &--dots {
            margin: 5px 0 0;
            padding-left: 0;

            .swiper-pagination-bullet-active {
                background: $white;
            }
        }
    }
}
