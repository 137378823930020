.section--component-2 {
    position: relative;
    z-index: 2;

    + .section--component-3,
    + span + .section--component-3 {
        &:not([class*="bg-"]) {
            .component-3 {
                padding-top: 0;
            }
        }
    }
}

.component-2 {
    margin-top: -32px;

    @include media-breakpoint-up(lg) {
        margin-top: -57px;
    }
    .container {
        @include media-breakpoint-up(xxxl) {
            max-width: 1274px;
        }
    }
    .input-group {
        margin-bottom: 24px;

        background: white;

        filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.15));
    }
    .form-control {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;

        height: 66px;
        padding: 22px 22px 22px 63px;

        border: 0;
        border-radius: 0;
        background: transparent;

        @include media-breakpoint-up(lg) {
            font-size: 27px;
            font-weight: 500;
            line-height: 140%;

            height: 86px;
            padding: 24px 24px 24px 64px;
        }
        &::placeholder {
            color: $navy;
        }
    }
    .input-group-text {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;

        padding: 22px 0 22px 20px;

        color: $navy;
        border: 0;
        border-radius: 0;
        background: white;

        @include media-breakpoint-up(lg) {
            padding: 24px 0 24px 21px;
        }
    }
    .form-check-label {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        text-decoration: none;

        color: $navy;
        .template--cao & {
            color: white;
        }
    }
    .form-check-input {
        border: solid 1px #949494;
        &:checked[type="checkbox"] {
            background-color: white;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.2L5.5 9L13 1' stroke='%2300A2E1' stroke-width='2'/%3E%3C/svg%3E%0A");
            background-size: 14px 11px;
        }
    }
}
