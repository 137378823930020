.section--component-27 {
    position: relative;
    z-index: 2;
    + .section--component-20,
    + span + .section--component-20 {
        .component-20 {
            padding-bottom: 100px;
        }
    }
    + .section--component-27,
    + span + .section--component-27 {
        .component-27 {
            margin-top: -60px;
            padding-top: 40px;
        }
    }
    + .section--component-23,
    + span + .section--component-23 {
        .component-23 {
            padding-top: 60px;
        }
        &.bg-white {
            margin-top: -40px;
            padding-top: 0;
        }
    }
    + .section--component-3,
    + span + .section--component-3 {
        .component-3 {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
}
.component-27 {
    padding-top: 50px;
    padding-bottom: 80px;

    background: white;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 80px;
    }
    .container {
        @include media-breakpoint-up(xxl) {
            max-width: calc(1244px + 24px);
            padding-right: 12px;
            padding-left: 12px;
        }
        @include media-breakpoint-down(sm) {
            overflow-x: auto;
        }
    }
    h2 {
        font-weight: 700;
        line-height: 120%;

        margin-bottom: 22px;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(md) {
            font-size: 42px;
        }
    }
    h3 {
        font-weight: 600;
        line-height: 120%;

        margin-bottom: 22px;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(md) {
            font-size: 34px;
        }
    }
    h4 {
        font-weight: 500;
        line-height: 140%;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(md) {
            font-size: 27px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        margin-bottom: 20px;

        color: #333333;
    }
    img {
        max-width: 100%;
        margin-bottom: 20px;
    }
    ul {
        margin-bottom: 22px;
        padding-left: 32px;

        list-style: none;

        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            position: relative;

            margin-bottom: 8px;

            color: #333333;
            &::before {
                line-height: 140%;

                position: absolute;
                top: 8px;
                left: -16px;

                width: 8px;
                height: 8px;

                content: "";

                border-radius: 50%;
                background: $blue;
            }
            a {
                text-decoration: underline;

                color: inherit;

                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                text-decoration-color: $blue;
            }
        }
    }
}
