.section--component-content {
    position: relative;
    z-index: 2;
    + .section--component-27,
    + span + .section--component-27 {
        .component-27 {
            margin-top: -50px;
            padding-top: 50px;

            @include media-breakpoint-up(lg) {
                padding-top: 50px;
            }
        }
    }
    + .section--component-27,
    + span + .section--component-27 {
        .component-27 {
            margin-top: -50px;
            padding-top: 50px;

            @include media-breakpoint-up(lg) {
                padding-top: 50px;
            }
        }
    }
    + .section--component-20,
    + span + .section--component-20 {
        margin-top: -50px;
        padding-top: 100px;
        .component-20 {
            padding-top: 40px;
        }
        &.bg-white .component-20 {
            padding-top: 0;
        }
    }
}

.component-content {
    padding: 100px 0;

    .row.row--custom {
        @include media-breakpoint-up(xl) {
            margin-right: -16px;
            margin-left: -16px;

            .dynamic-col {
                padding-right: 16px;
                padding-left: 16px;
            }
        }
    }

    .container {
        .container {
            padding: 0;
        }
    }

    .component-10,
    .component-11,
    .component-16,
    .component-17,
    .component-18,
    .component-28,
    .component-3 {
        padding: 0;
    }

    .input-group {
        position: relative;
        // margin-top: 40px;

        margin-bottom: 68px;

        @include media-breakpoint-up(xl) {
            // margin-top: 50px;
            margin-bottom: 97px;
        }

        .input-group-text {
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            left: 0;

            width: 64px;

            border: none;
            background: transparent;

            svg {
                margin: auto;
            }
        }

        .form-control[type="search"],
        .search-query {
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;

            padding: 22px 24px 20px 62px;

            letter-spacing: -0.01em;

            color: $navy;
            border: 1px solid $blue-a11y;
            border-radius: 0;

            @include media-breakpoint-up(xl) {
                font-size: 27px;
                line-height: 140%;

                padding: 23px 24px 23px 64px;
            }

            &::placeholder {
                color: $navy;
            }
        }
    }

    &__nav {
        display: flex;

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }

        justify-content: space-between;
        flex-wrap: wrap;

        .nav-item {
            margin-right: 10px;
            margin-bottom: 42px;

            @include media-breakpoint-up(sm) {
                margin-right: 48px;
                margin-bottom: 50px;
            }
        }

        .nav-link {
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;

            padding-right: 0;
            padding-bottom: 11px;
            padding-left: 0;

            text-transform: capitalize;

            color: $navy;
            border: none;
            border-bottom: solid 1px $blue;
            background: transparent;

            @include media-breakpoint-up(sm) {
                font-size: 27px;
            }

            &.active {
                padding-bottom: 8px;

                border-block-width: 4px;
            }
        }
    }

    &__view {
        margin-bottom: 50px;
    }

    &__list {
        // display: flex;
        margin-top: 50px;
        padding-left: 0;

        list-style: none;

        flex-wrap: wrap;

        button {
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;

            display: inline-block;

            margin-right: 10px;
            padding-bottom: 10px;

            text-align: center;
            text-decoration: none;
            letter-spacing: -0.01em;
            text-transform: uppercase;

            color: $navy;
            border: none;
            background: none;

            @include media-breakpoint-up(xl) {
                min-width: 20px;
                margin-right: 15px;
            }
            @include media-breakpoint-up(xxl) {
                min-width: 25px;
            }
            @include media-breakpoint-up(xxxl) {
                min-width: 30px;
                margin-right: 20px;
            }

            &.active,
            &:hover {
                padding-bottom: 5px;

                border-bottom: solid 5px $blue;
            }
        }
    }

    img {
        max-width: 100%;
    }
    ul:not([class]) {
        margin-bottom: 22px;
        padding-left: 32px;

        list-style: none;

        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            position: relative;

            margin-bottom: 8px;

            color: #333333;
            &::before {
                line-height: 140%;

                position: absolute;
                top: 8px;
                left: -16px;

                width: 8px;
                height: 8px;

                content: "";

                border-radius: 50%;
                background: $blue;
            }
            a {
                color: inherit;
                &:hover {
                    text-decoration: underline;

                    text-underline-offset: 2px;
                    text-decoration-thickness: 2px;
                    text-decoration-color: $blue;
                }
            }
        }
    }
}

.filter {
    top: 20px;

    transition: all 0.4s linear;

    &__group {
        padding-bottom: 40px;

        border-bottom: solid 1px rgba(black, 0.15);

        @include media-breakpoint-up(xl) {
            max-width: 271px;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 40px;
            &:first-of-type {
                padding-top: 0;
            }
            &.border-0 {
                max-width: 290px;
            }
        }
        .event-search-widget & {
            padding-top: 0;
        }
        .filter-body {
            padding-top: 20px;
        }
        &.border-0 {
            padding-top: 0;
        }

        .form-check.collapse {
            padding-left: 0;

            .form-check {
                > input {
                    border: 0;

                    appearance: none;
                }
            }
        }

        + .filter__group {
            padding-top: 30px;

            &.border-0 {
                padding-top: 0;
            }
        }

        &:nth-child(2) {
            // border-top: solid 1px rgba(black, 0.15);
            @include media-breakpoint-down(lg) {
                padding-top: 30px;
            }
        }

        .form-control {
            font-size: 16px !important;
            font-weight: 500;
            line-height: 23px;

            @include media-breakpoint-up(xl) {
                font-size: 18px !important;
            }
        }
    }

    &__clear {
        .btn {
            min-width: 155px;
        }
    }

    .input-group {
        position: relative;
        // margin-top: 40px;

        margin-bottom: 68px;

        @include media-breakpoint-up(xl) {
            // margin-top: 50px;
            margin-bottom: 97px;
        }

        .input-group-text {
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            left: 0;

            width: 64px;

            border: none;
            background: transparent;

            svg {
                margin: auto;
            }
        }

        .form-control {
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;

            padding: 22px 24px 20px 62px;

            letter-spacing: -0.01em;

            color: $navy;
            border: 1px solid $blue;
            border-radius: 0;

            @include media-breakpoint-up(xl) {
                font-size: 27px;
                line-height: 140%;

                padding: 23px 24px 23px 64px;
            }

            &::placeholder {
                color: $navy;
            }
        }
    }

    .collapse {
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.filter-header {
    display: block;

    margin-bottom: 0;


    button {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        padding: 0;

        letter-spacing: -0.01em;

        color: $navy;
        border: none;
        background: none;
    }
}

.filter-button {
    position: relative;

    display: block;

    width: 100%;

    text-align: left;

    @include media-breakpoint-up(lg) {
        pointer-events: none;

        svg {
            display: none;
        }
    }

    span {
        position: absolute;
        top: 0;
        right: 20px;

        display: block;

        transition: all 0.25s linear;

        svg {
            width: 18px;
            height: 18px;

            transform: rotate(180deg);
        }
    }

    &.collapsed {
        svg {
            transform: rotate(0deg);
        }
    }
}

.form-check.collapse:first-of-type {
    .filter-body {
        padding-top: 20px;
    }
}

.filter-body {
    .form-check {
        margin-bottom: 15px;
    }
}

blockquote {
    margin: 1.5em 10px;
    padding: 0.5em 10px;

    quotes: "\201C" "\201D" "\2018" "\2019";

    border-left: 10px solid $navy;
    background: #F9F9F9;
}

blockquote:before {
    font-size: 4em;
    line-height: 0.1em;

    margin-right: 0.25em;

    content: open-quote;
    vertical-align: -0.4em;

    color: $navy;
}

blockquote p {
    display: inline;
}

table:not([class]) {
    font-family: Tahoma, Geneva, sans-serif;

    max-width: 100%;

    border-collapse: collapse;
}

table:not([class]) td {
    padding: 10px;

    @include media-breakpoint-down(sm) {
        font-size: 14px;

        padding: 4px;
    }
}

table:not([class]) thead td,
table:not([class]) thead th {
    font-weight: bold;

    padding: 10px;

    color: #FFFFFF;
    border: 1px solid $navy;
    background-color: $navy;

    @include media-breakpoint-down(sm) {
        font-size: 14px;

        padding: 4px;
    }
}

table:not([class]) tbody td {
    border: 1px solid $navy;
}

table:not([class]) tbody tr {
    background-color: $grey-light;
}

table:not([class]) tbody tr:nth-child(odd) {
    background-color: #FFFFFF;
}
.tox-tinymce {
    width: 100%;
    margin-bottom: 39px;

    background: white;

    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.15));
}
