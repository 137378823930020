.section--component-12 {
    position: relative;
    z-index: 10;

    margin-bottom: 10%;

    transform: skew(0deg, -10deg);

    opacity: 1 !important;

    + .section--component-23,
    + span + .section--component-23 {
        margin-top: 0;
        padding-top: 0;

        .component-23 {
            padding-top: 80px;
            padding-bottom: 100px;
        }
    }

    &.bg-flow {
        &:before {
            position: absolute;
            z-index: -2;
            top: 100%;
            left: 0;

            display: block;

            width: 100%;
            height: 455px;

            content: "";

            background: $grey-light;
        }
    }

    .rectangle {
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;

        content: "";

        &__item {
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;
            overflow: hidden;

            width: 200px;
            height: 50px;
            // transform: translateY(50%) translateX(-50%) skew(0deg, -10deg);

            transform: translateY(50%) translateX(-50%);

            background: rgba($blue, 0.8);

            @include media-breakpoint-up(lg) {
                width: 570px;
                height: 100px;
            }
        }

        &__red {
            right: 0;
            bottom: -15px;
            left: auto;

            @include media-breakpoint-up(xxl) {
                bottom: -20px;
            }

            .rectangle__item {
                width: 50vw;
                height: 70px;

                transform: translateY(100%) translateX(-100%);

                background: rgba($red, 0.8);

                @include media-breakpoint-up(sm) {
                    width: 40vw;
                }
                @include media-breakpoint-up(md) {
                    width: 250px;
                }
                @include media-breakpoint-up(lg) {
                    width: 500px;
                    height: 120px;
                }
                @include media-breakpoint-up(xxl) {
                    width: 770px;
                    height: 160px;
                }
            }
        }
    }
}

.component-12 {
    margin-top: -20%;
    padding-top: 20%;
    padding-bottom: 4%;

    transform: skew(0deg, 10deg);

    @include media-breakpoint-between(lg, xl) {
        min-height: 450px;
    }
    @include media-breakpoint-between(md, lg) {
        min-height: 500px;
    }


    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__content {
        display: flex;
        flex-direction: column;

        max-width: calc(100% - 80px);
        padding-top: 140px;

        @include media-breakpoint-up(lg) {
            max-width: 837px;
            padding-top: 180px;
        }
        @include media-breakpoint-down(md) {
            min-height: 260px;
        }

        flex-wrap: wrap;
    }

    &__subtitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 8px;

        text-transform: uppercase;

        color: white;

        @include media-breakpoint-up(lg) {
            order: -1;
        }
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        color: white;

        @include media-breakpoint-up(lg) {
            font-size: 67px;
            font-weight: 700;
        }

        &.title-small {
            font-size: 30px;

            @include media-breakpoint-up(sm) {
                font-size: 36px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 42px;
                font-weight: 700;
                line-height: 120%;
            }
        }
    }

    &__subheading {
        font-weight: 500;
        line-height: 120%;

        color: white;
    }
}
