.pagination {
    .page-item {
    }
    .page-link {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;

        padding: 14px 22px;

        color: black;
        border-color: white;
        border-radius: 0!important;
        background: $grey-light;

        @include media-breakpoint-up(lg) {
            padding: 24px 32px;
        }
    }
}
