.component-28 {
    padding-top: 100px;
    padding-bottom: 80px;

    background: white;
    .container {
        @include media-breakpoint-up(xxl) {
            max-width: calc(1244px + 24px);
            padding-right: 12px;
            padding-left: 12px;
        }
    }

    h2 {
        font-weight: 700;
        line-height: 120%;

        margin-bottom: 22px;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(md) {
            font-size: 42px;
        }
    }
    h3 {
        font-weight: 600;
        line-height: 120%;

        margin-bottom: 22px;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(md) {
            font-size: 34px;
        }
    }
    h4 {
        font-weight: 500;
        line-height: 140%;

        letter-spacing: -0.01em;

        @include media-breakpoint-up(md) {
            font-size: 27px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        margin-bottom: 20px;

        color: #333333;
    }
    img {
        max-width: 100%;
        margin-bottom: 20px;
    }
    ul:not([class]) {
        margin-bottom: 22px;
        padding-left: 32px;

        list-style: none;

        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            position: relative;

            margin-bottom: 8px;

            color: #333333;
            &::before {
                line-height: 140%;

                position: absolute;
                top: 8px;
                left: -16px;

                width: 8px;
                height: 8px;

                content: "";

                border-radius: 50%;
                background: $blue;
            }
            a {
                text-decoration: underline;

                color: inherit;

                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                text-decoration-color: $blue;
            }
        }
    }
    &__head {
        margin-bottom: 40px;
        padding-bottom: 30px;

        border-bottom: solid 10px $gold;
    }
    &__list {
        margin-bottom: 22px;
        padding-left: 0;

        list-style: none;
        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            position: relative;

            margin-bottom: 11px;

            color: #333333;
            &::before {
                display: none;
            }
            span {
                display: inline-block;

                min-width: 30px;

                color: $blue;
            }
        }
    }
}
