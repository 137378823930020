// !: Elements – Content

// ::: TinyMCE button styling
a[class*=" tinymce-btn"],
a[class^="tinymce-btn"],
p[class*=" tinymce-btn"],
p[class^="tinymce-btn"] {
    @extend .btn;
    &.tinymce-btn-blue {
        @extend .btn-blue;
    }
    &.tinymce-btn-primary {
        @extend .btn-primary;
    }
    &.tinymce-btn-gold {
        @extend .btn-gold;
    }
    &.tinymce-btn-red {
        @extend .btn-red;
    }
    &.tinymce-btn-white {
        @extend .btn-white;
    }
    &.tinymce-btn-outline-blue {
        @extend .btn-outline-blue;
    }
    &.tinymce-btn-outline-primary {
        @extend .btn-outline-primary;
    }
    &.tinymce-btn-outline-gold {
        @extend .btn-outline-gold;
    }
    &.tinymce-btn-outline-red {
        @extend .btn-outline-red;
    }
}
p[class*=" tinymce-btn"],
p[class^="tinymce-btn"] {
    a {
        text-decoration: none;

        color: inherit;
    }
}
.btn {
    text-transform: uppercase;

@include media-breakpoint-down(lg) {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;

        padding: 12px 16px;

        letter-spacing: 0.03em;
    }

    &-white {
        color: $navy;
        background: white;
    }

    a {
        text-decoration: none;

        color: inherit;
    }
}

.mtu-shadow-soft {
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
}

nav.pagination {
    @include media-breakpoint-down(sm) {
        justify-content: center;
    }

    .currentpage {
        color: white;
        border-color: $blue;
        background-color: $blue;
    }

    span,
    a {
        @extend .page-link;
    }
}

.breadcrumb {
    &__wrapper {
        margin-bottom: 50px;
    }

    .linkItem {
        @extend .breadcrumb-item;

        &:last-child {
            text-decoration: none;
            pointer-events: none;

            color: $grey-mid-lighter;

            a {
                text-decoration: none;
                pointer-events: none;

                color: $grey-mid-lighter;
            }
        }
    }
}

.side-nav {
    &__wrapper {
        margin-bottom: 50px;

        nav > ul {
            @extend .side-nav;
        }
        .toggleSidemenu {
            font-size: 20px;
            font-weight: bold;

            display: flex;

            width: 100%;
            padding: 15px 10px;

            color: $navy;
            border: none;
            border-bottom: solid 2px rgba(0, 62, 125, 0.1);

            align-items: center;
            justify-content: space-between;
            i {
                display: inline-block;

                width: 24px;
                height: 24px;
                margin-left: 10px;

                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.1641 0H11.1641V10.833H0V12.833H11.1641V24H13.1641V12.833H24V10.833H13.1641V0Z' fill='%23003E7D'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
            }
            &.active i {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='2' viewBox='0 0 24 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2V0H24V2H0Z' fill='%23003E7D'/%3E%3C/svg%3E%0A");
            }
        }
        #sidebar {
            @include media-breakpoint-down(lg) {
                overflow: hidden;

                max-height: 0;
                padding: 0;

                transition: all 0.3s linear;
                &.active {
                    max-height: 1500px;
                }
            }
        }
    }

    &-header {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        padding: 0;

        text-decoration: none;
        letter-spacing: -0.01em;

        color: $navy;
        border: none;
        background: none;

        a {
            text-decoration: none;

            color: inherit;
        }
    }

    li {
        margin-bottom: 10px;

        &::marker {
            color: $navy;
        }
    }
}

#sidebar {
    display: block;

    padding: 5px 15px 10px;

    .sidebar-nav {
        padding-left: 0;
    }

    .currentbranch {
        display: block;

        a {
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;

            text-decoration: none;
            letter-spacing: -0.01em;
            text-transform: uppercase;

            color: $blue;
        }
    }

    .currentbranch0,
    .currentbranch1,
    .currentbranch2,
    .currentbranch3,
    .currentbranch4,
    .currentbranch5 {
        position: relative;

        display: block;

        &::before {
            position: absolute;
            top: 10px;
            bottom: 10px;
            left: -30px;

            width: 5px;

            content: "";

            background: $blue;
        }

        a {
            font-weight: 700;

            color: $navy;
        }

        + ul {
            display: block !important;

            margin-left: 20px;

            ul {
                display: none;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;

        list-style: none;
    }

    > ul {
        li {
            a {
                position: relative;

                display: block;

                padding: 15px 0;

                text-decoration: none;

                &:before,
                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;

                    width: 100%;
                    height: 2px;

                    content: "";

                    background: rgba($navy, 0.1);
                }

                &:after {
                    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
                    transform: scaleX(0);
                    transform-origin: center left;

                    background: rgba($navy, 0.2);
                }

                &:hover {
                    color: $blue;

                    &:after {
                        transform: scale(1);
                    }
                }

                &.is-parent {
                    padding-right: 17px;

                    background-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 1L8.5 8L15.5 1' stroke='%23ccc' stroke-width='2'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: 98% center;
                    background-size: 17px 10px;
                }
            }
        }

        > li {
            border-bottom: none;

            ul {
                a {
                    color: $dark-grey;
                }
            }

            > ul {
                > li {
                    > ul {
                        display: none;
                    }
                }
            }
        }
    }
}

.js-label-text {
    @extend .form-label;
}

.js-elements-container .form-group {
    display: flex;

    margin-bottom: 15px;

    flex-wrap: wrap;

    .red {
        color: $red;
    }

    label:not([class]) {
        margin-top: 15px;

@extend .form-check;

        input[type="checkbox"] {
            @extend .form-check-input;

            + span {
                @extend .form-check-label;
            }
        }
    }

    .form-control {
        margin-bottom: 24px;
        padding: 15px;

        border: 0;
        border-radius: 0;
        background: white;

        filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.15));
    }

    .control-label {
        @extend .form-label;
    }

    input[type="file"] {
        @extend .form-control;
    }

    button {
        &.pull-right {
            margin-left: auto;
        }
    }
}

.toast {
    &--notice {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        position: relative;
        z-index: $zindex-modal;

        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;

        letter-spacing: -0.01em;

        color: $navy;
        border-radius: 0;
        background: $gold;

        .btn {
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;

            display: inline-flex;

            margin-left: 5px;
            padding-top: 8px;
            padding-bottom: 8px;

            vertical-align: unset;
            text-decoration: none;

            &:focus {
                box-shadow: 0 0 0 0.25rem #003E7D;
            }
        }
    }

    &-body {
        position: relative;

        .btn-close {
            position: absolute;
            top: 50%;
            right: 0;

            margin: auto;

            transform: translateY(-50%);
        }
    }
}

.side-nav {
    margin-right: -12px;
    margin-left: -12px;
    padding-left: 0;

    list-style: none;

    ul {
        padding-left: 15px;

        list-style: none;
    }

    li {
        > a {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            display: block;

            width: 100%;
            padding: 6px 12px;

            text-decoration: none;

            color: $dark-grey;

            &:hover {
                text-decoration: underline;

                color: $blue;

                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                text-decoration-color: #00A2E1;
            }
        }

        span {
            display: block;

            padding: 6px 12px;

            a {
                font-size: 16px;
                font-weight: 600;
                line-height: 140%;

                display: block;

                text-decoration: none;

                color: $navy;
            }
        }
    }

    &__wrapper {
        padding: 20px 15px;

        background: $grey-lighter;

@include media-breakpoint-up(lg) {
            max-width: 290px;
        }
    }
}
