.section--component-23 {
    position: relative;
    z-index: 0;

    overflow: hidden;

    margin-top: -200px;
    margin-bottom: -1px;
    padding-top: 200px;

    transform: skew(0deg, -10deg);
    &.bg-simple {
        margin-top: 0;
        padding-top: 0;

        transform: none;
    }
    &.bg-none {
        z-index: 1;

        margin-top: 0;
        padding-top: 0;
    }
    + .section--component-6 {
        z-index: 0;

        @include media-breakpoint-up(lg) {
            margin-top: 0;
            margin-bottom: -200px;
            padding-bottom: 200px;
        }
    }
    + .section--component-20,
    + span + .section--component-20 {
        margin-top: 0;
    }
    + .section--component-3,
    + span + .section--component-3 {
        margin-top: -20px;

        @include media-breakpoint-up(lg) {
            margin-top: -40px;
        }
    }
    + .section--component-4,
    + span + .section--component-4 {
        z-index: 2;

        margin-top: -15%;

        @include media-breakpoint-up(lg) {
            margin-top: 15%;
        }
    }
    &.bg-white .component-23 {
        padding-bottom: 50px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 100px;
        }
    }
    + .section--component-27,
    + span + .section--component-27 {
        z-index: 2;

        margin-top: -50px;


        .component-27 {
            padding-top: 50px;
        }
    }
    &.bg-white + .section--component-8.bg-white,
    &.bg-white + span + .section--component-8.bg-white {
        .component-8 {
            padding-top: 0;
        }
    }
}

.component-23 {
    position: relative;

    padding-top: 10%;
    padding-bottom: 10%;

    transform: skew(0deg, 10deg);
    .bg-simple & {
        padding-top: 100px;
        padding-bottom: 100px;

        transform: none;
    }
    .bg-none & {
        padding-top: 100px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 100px;
        }
    }

    &__wrapper {
        position: relative;
        z-index: 1;
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        margin-bottom: 16px;
        padding-top: 10px;

        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 40px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }
        &.title-small {
            @include media-breakpoint-up(lg) {
                font-size: 42px;
                font-weight: 700;
                line-height: 120%;
            }
        }
    }
    .section__subtitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        max-width: 900px;
        margin-right: auto;
        margin-bottom: 40px;
        margin-left: auto;

        letter-spacing: -0.01em;

        color: $navy;
        &.text-start {
            margin-left: 0;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
    }
    .container {
        @include media-breakpoint-up(xxl) {
            max-width: 1274px;
        }
    }
    .accordion {
        max-width: 1250px;
        margin-right: auto;
        margin-left: auto;
    }
    .accordion-item {
        margin-bottom: 15px;

        background: $grey-light;

        @include media-breakpoint-up(lg) {
            margin-bottom: 8px;
        }
        .bg-grey-light & {
            background: white;
        }
    }
    .accordion-button {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        position: relative;

        width: 100%;
        padding-top: 28px;
        padding-right: 60px;
        padding-bottom: 28px;
        padding-left: 30px;

        text-align: left;

        color: $navy;
        border: none;
        background: none;
        svg {
            position: absolute;
            top: 50%;
            right: 33px;

            transform: translateY(-50%);
        }
    }
    .accordion-header {
        margin-bottom: 0;
    }
    .accordion-body {
        padding: 12px 30px;
    }
}
