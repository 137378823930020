.section--component-22 {
    position: relative;
    z-index: 11;

    min-height: 400px;
    margin-top: 100px;

    @include media-breakpoint-up(md) {
        margin-top: 150px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 200px;
    }
    @include media-breakpoint-up(xl) {
        margin-top: 230px;
    }

    &:before {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 100%;

        content: "";

        background: initial;
    }

    .rectangle {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;

        content: "";

        &__item {
            position: absolute;
            right: 0;
            bottom: 0;

            display: block;
            overflow: hidden;

            width: 90px;
            height: 50px;

            transform: skew(0, -13deg) translateY(50%) ;
            // transform: skew(0, -13deg) translateY(0%) ;

            background: rgba($gold, 0.8);

            @include media-breakpoint-up(lg) {
                width: 210px;
                height: 170px;
            }
        }
    }
}
.component-22 {
    position: relative;
    z-index: 2;

    padding: 0 0 100px;

    .container {
        @include media-breakpoint-up(xxxl) {
            max-width: 1274px;
        }
    }

    &__body {
        max-width: 610px;
        p {
            font-size: 24px;
            font-weight: 500;
            line-height: 140%;

            letter-spacing: -0.01em;

            color: $navy;

            @include media-breakpoint-up(lg) {
                font-size: 27px;
            }
        }
    }
    &__item {
        display: flex;

        margin-bottom: 24px;

        border-bottom: 1px dashed rgba(black,0.4);

        align-items: flex-start;
    }
    &__itemTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;

        display: block;

        margin-bottom: 8px;

        text-transform: uppercase;

        color: black;
    }
    &__itemText {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 16px;

        color: black;
    }
    &__text {
        margin-left: 15px;
    }
    &__image {
        position: relative;
        z-index: 111;

        display: block;

        width: 146px;
        height: 146px;
        margin-bottom: 28px;

        @include media-breakpoint-up(lg) {
            width: 277px;
            height: 277px;
            margin-top: -50%;
        }
        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
}
