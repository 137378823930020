.section--component-4 {
    position: relative;
    z-index: 1;

    margin-top: 16%;
    margin-bottom: -15%;

    transform: skew(0deg, -10deg);

    // background: $red;
    +.section--component-8,
    +span+.section--component-8 {
        z-index: 2;
    }

    +.section--component-23,
    +span+.section--component-23 {
        z-index: 2;

        margin-top: 0;
        padding-top: 0;

        .component-23 {
            padding-top: 20px;
        }
    }

    .template--cao & {

        +.section--component-3 .component-3,
        +span .section--component-3 .component-3 {
            padding-top: 5%;
        }
    }
}

$rectangleHeight: 18vw;

.component-4 {
    position: relative;

    overflow: hidden;

    margin-top: -10%;
    margin-bottom: -1px;
    padding-top: 0;
    padding-bottom: 0;

    transform: skew(0deg, 10deg) translateY(-16%);

    color: white;
    background: inherit;

    @include media-breakpoint-down(lg) {
        margin-top: 16%;

        transform: skew(0deg, 10deg) translateY(-10%);
    }

    will-change: transform;

    &::after {
        position: absolute;
        z-index: 99;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: block;

        width: 70%;
        height: 100%;

        content: "";

        background: linear-gradient(90.03deg, rgba(0, 0, 0, 0.664) -0.29%, rgba(0, 0, 0, 0) 82.54%);

        .gradient-blue & {
            background: linear-gradient(90deg, rgba(0, 67, 131, 0.9) 0%, rgba(3, 78, 162, 0) 100%);
        }
    }

    .rectangle {
        position: absolute;
        z-index: 100;
        right: 0;
        bottom: -1px;
        left: 0;

        height: $rectangleHeight;

        transform: skew(0deg, -10deg) translateY(50%);

        background: inherit;

        .template--cao & {
            background: $navy;
        }

        // &::before {
        //     position: absolute;
        //     z-index: 100;
        //     right: 0;
        //     bottom: -1px;
        //     left: 0;

        //     width: 100%;
        //     height: $rectangleHeight;

        //     content: "";

        //     background-image: linear-gradient(to left top, white 0%, white 50%, transparent 50%);
        // }
        &--slant {
            position: absolute;
            z-index: 100;
            right: 0;
            bottom: -1px;
            left: 0;

            height: $rectangleHeight;


            &:before {
                position: absolute;
                z-index: 101;
                top: 0;
                right: 10%;

                display: block;
                overflow: hidden;

                width: 200px;
                height: 50px;

                content: "";
                transform: translateY(-50%) translateX(70%);

                background: rgba($blue, 0.8);

                @include media-breakpoint-up(lg) {
                    width: 400px;
                    height: 80px;

                    transform: translateY(-50%);
                }
            }
        }
    }

    &__bg {
        position: relative;

        overflow: hidden;

        min-height: 650px;

        background: inherit;

        @include media-breakpoint-up(sm) {
            min-height: 760px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 1045px;
        }


        img {
            position: absolute;
            top: 50%;
            right: -50%;
            left: -50%;

            width: 100%;
            min-height: 100%;
            margin: auto;

            transform: translateY(-50%);

            @include media-breakpoint-down(sm) {
                max-width: 817px;
            }

            object-fit: cover;
            filter: blur(1px);
        }
    }

    &__wrapper {
        position: absolute;
        z-index: 101;
        right: 0;
        bottom: 100px;
        left: 0;

        @include media-breakpoint-up(lg) {
            top: 40%;
            bottom: auto;

            transform: translateY(-50%);
        }
    }

    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        margin-bottom: 16px;
        padding-top: 10px;

        color: inherit;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 24px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }

        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        max-width: 837px;
        margin-bottom: 32px;

        letter-spacing: -0.01em;
    }

    button.video-btn {
        margin-top: 100px;
        margin-left: 200px;

        border: none;
        background: transparent;

        &:hover {
            filter: drop-shadow(5px 5px 25px rgba(black, 0.6));
        }
    }
}

.modal[id^=videoModal] {
    .modal-dialog {
        max-width: 800px;
        margin: 30px auto;
    }

    .modal-body {
        position: relative;

        padding: 0;
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: -30px;
    }
}