.section--component-17 {
    position: relative;
    z-index: 2;
}

.component-17 {
    padding: 100px 0;
    &__results {
        display: block;

        @include media-breakpoint-up(sm) {
            margin-top: 50px;
        }
    }
    &__list {
        display: flex;

        margin-top: 50px;
        margin-right: -10px;
        margin-left: -10px;
        padding-left: 0;

        list-style: none;

        flex-wrap: wrap;
        li {
            padding-right: 10px;
            padding-left: 10px;

            // flex: 1;
            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 140%;

                display: block;

                padding-bottom: 8px;

                text-align: center;
                text-decoration: none;
                letter-spacing: -0.01em;
                text-transform: uppercase;

                @include media-breakpoint-up(xl) {
                    min-width: 20px;
                }
                @include media-breakpoint-up(xxl) {
                    min-width: 25px;
                }
                @include media-breakpoint-up(xxxl) {
                    min-width: 30px;
                }
                &.active,
                &:hover {
                    padding-bottom: 5px;

                    border-bottom: solid 3px $blue;
                }
            }
        }
    }
    &__course {
        display: block;

        margin-bottom: 50px;
    }
    &__letter {
        font-size: 28px;
        font-weight: 700;
        line-height: 120%;

        display: block;

        margin-bottom: 25px;
        padding-bottom: 25px;

        letter-spacing: -0.01em;

        color: $navy;
        border-bottom: 10px solid $gold;

        @include media-breakpoint-up(xl) {
            font-size: 34px;
        }
    }
    &__course {
        display: block;

        margin-bottom: 50px;
    }
    &__letter {
        font-size: 28px;
        font-weight: 700;
        line-height: 120%;

        display: block;

        margin-bottom: 25px;
        padding-bottom: 25px;

        letter-spacing: -0.01em;

        color: $navy;
        border-bottom: 10px solid $gold;

        @include media-breakpoint-up(xl) {
            font-size: 34px;
        }
    }
    &__linkTitle {
        font-size: 28px;
        font-weight: 700;
        line-height: 150%;

        display: block;

        margin-bottom: 24px;

        text-decoration: none;

        color: $navy;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
            font-weight: 600;
            line-height: 120%;

            letter-spacing: -0.01em;
        }
    }
    &__details {
        display: flex;

        margin-bottom: 30px;
        padding-bottom: 29px;

        border-bottom: solid 1px rgba(black,0.15);

        flex-wrap: wrap;
        align-items: center;
        span {
            display: flex;

            margin-right: 34px;

            color: $dark-grey;

            @include media-breakpoint-up(lg) {
                margin-right: 40px;
            }

            align-items: center;
            img,
            svg {
                margin-right: 8px;
            }
        }
    }
}
