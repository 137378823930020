.section--component-5 {
    position: relative;
    z-index: 1;

    margin-bottom: -1px;

    transform: skew(0deg, -10deg);

    // background: $red;
    + .section--component-23,
    + span + .section--component-23 {
        @include media-breakpoint-up(lg) {
            padding-top: 12%;
        }
        @include media-breakpoint-up(xl) {
            padding-top: 110px;
        }
        @include media-breakpoint-up(xxl) {
            padding-top: 6%;
        }
        @include media-breakpoint-up(xxxl) {
            padding-top: 5%;
        }
    }
}

.component-5 {
    position: relative;

    padding-top: 10%;
    padding-bottom: 10%;

    transform: skew(0deg, 10deg);

    color: $navy;
    &__wrapper {
        position: relative;
        z-index: 1;
    }
    .section__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 120%;

        margin-bottom: 16px;
        padding-top: 10px;

        color: inherit;

        @include media-breakpoint-up(lg) {
            font-size: 50px;
            font-weight: 700;

            margin-bottom: 24px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 60px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxxl) {
            font-size: 90px;
        }
    }
    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;

        max-width: 837px;

        letter-spacing: -0.01em;
    }
    &__fig {
        position: relative;

        @include media-breakpoint-up(xl) {
            margin-top: -20%;
        }
        @include media-breakpoint-up(xxl) {
            margin-top: -30%;
        }
        @include media-breakpoint-up(xxxl) {
            margin-top: -40%;
        }
        img {
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                margin-bottom: -10%;

                transform: skew(0deg, 10deg);
            }
        }
    }
    &__img {
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            transform: skew(0deg, -10deg);
        }
    }
    .container {
        position: relative;
    }
    .stats {
        color: $blue;
        background: white;

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 65%;
            right: 0;
            left: 0;

            max-width: calc(100% - 320px);
            margin: auto;
            padding: 0 37px;

            transform: translateY(-50%);
        }
        em {
            color: darken($blue,2%);
            background: white;
        }
    }
    .odometer-content {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        margin-bottom: 32px;

        text-align: center;

        color: $navy;
    }
    .odometer-container {
        font-size: 90px;
        font-weight: 700;
        line-height: 120%;

        display: flex;

        margin: 11px auto;
        padding-top: 32px;

        white-space: nowrap;
        letter-spacing: -0.01em;

        @include media-breakpoint-up(lg) {
            font-size: 70px;
        }
        @include media-breakpoint-up(xxl) {
            font-size: 90px;
        }

        justify-content: center;
        em {
            font-style: normal;
            line-height: 1;

            display: inline-block;

            vertical-align: middle;
        }
    }
}
