// !: INIT Component – Footer

.footer {
    // position: relative;
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 0;
    left: 0;

    margin-top: 0;
    padding-top: 100px;
    padding-bottom: 50px;

    color: black;
    background: $grey-light;
    .footer__heading {
        background: inherit;
        h3 {
            font-size: 16px;
            font-weight: normal;

            margin-top: 0;
            margin-bottom: 0;

            color: inherit;
            background: inherit;
        }
    }
    .template--cao & {
        color: white;
        background: transparent;
    }
    // &:before {
    //     position: absolute;
    //     top: 0;

    //     width: 253px;
    //     height: 50px;

    //     content: "";
    //     transform: translateY(-45%) translateX(-43px) skew(-10deg) rotate(-10deg);

    //     opacity: 0.8;
    //     background: $gold;

    //     @include media-breakpoint-up(lg) {
    //         width: 400px;
    //         height: 100px;

    //         transform: translateY(-50%) translateX(-231px) skew(-10deg) rotate(-10deg);
    //     }
    // }
    // &:after {
    //     position: absolute;
    //     z-index: -2;
    //     right: 0;
    //     bottom: 0;

    //     width: 273px;
    //     height: 50px;

    //     content: "";

    //     opacity: 0.8;
    //     background: $red;

    //     @include media-breakpoint-up(lg) {
    //         width: 400px;
    //         height: 109px;
    //     }

    //     clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
    // }
    &__content {
        position: relative;

        padding-bottom: 50px;
    }
    .container {
        @include media-breakpoint-up(xl) {
            max-width: 1275px;
        }
    }
    .row {
        & + &.row {
            margin-top: 30px;
        }
    }
    &__heading {
        position: relative;

        display: block;

        margin-bottom: 17px;
        padding-bottom: 18px;

        color: $navy;

        @include media-breakpoint-up(lg) {
            margin-bottom: 19px;
            padding-bottom: 20px;
        }

        .template--cao & {
            color: white;
        }
        &::before {
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;

            width: 40px;
            height: 2px;
            margin-top: 19px;

            content: "";

            background-color: $gold;
        }
    }
    .col--wrapper {
        @include media-breakpoint-down(md) {
            margin-top: 50px;
            margin-right: -22px;
            margin-bottom: -26px;
            margin-left: -22px;

            .row {
                margin: 0;
            }
        }
    }
    .col--collapsible {
        @include media-breakpoint-down(md) {
            padding-right: 22px;
            padding-right: 22px;
            padding-left: 22px;

            border-top: solid 1px $secondary;
            ul {
                margin-bottom: 0;
                padding-top: 20px;
                padding-bottom: 35px;
            }
        }
        .footer__heading {
            display: block;

            padding-left: 0;

            @include media-breakpoint-down(md) {
                position: relative;

                margin-bottom: 0;
                margin-bottom: 17px;
                padding-top: 18px;
                padding-bottom: 16px;
                padding-left: 0;


                &::after {
                    font-family: "Line Awesome Free";
                    font-size: 24px;
                    font-weight: 900;

                    position: absolute;
                    top: 0;
                    right: 0;

                    display: block;

                    margin-top: 20px;

                    content: "\f107";

                    color: $gold;
                }
                &[aria-expanded="true"]::after {
                    content: "\f106";
                }
            }

            appearance: none;
            h3 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    ul {
        padding-left: 0;

        list-style-type: none;

        li,
        a {
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;

            color: black;
            .template--cao & {
                color: white;
            }
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;

                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                text-decoration-color: $blue;
            }
        }
    }
    &__list,
    &__address {
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 30px;
        }
        li {
            a {
                display: block;

                margin-bottom: 8px;
            }
        }
    }
    &__address {
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;

            margin-bottom: 0;

            color: black;
            a {
                color: $navy;
            }
            .template--cao & {
                color: white;
            }
        }
    }

    &__group {
        &--item {
            display: block;

            margin: 10px 0;
            .template--cao & {
                svg {
                    background: white;
                }
            }
        }
    }
    hr {
        margin: 26px -22px;

        opacity: 1;
        color: $secondary;

        @include media-breakpoint-up(md) {
            margin: 30px 0;
        }
    }
    .rectangle {
        position: absolute;
        z-index: -1;
        bottom: 100%;
        left: 0;

        content: "";


        &__item {
            position: absolute;
            z-index: -1;
            bottom: 100%;
            left: 0;

            display: block;
            overflow: hidden;

            width: 253px;
            height: 50px;

            transform: translateY(45%) translateX(-43px) skew(-10deg) rotate(-10deg);

            background: rgba($gold,0.8);

            @include media-breakpoint-up(lg) {
                bottom: calc(100% - 80px);

                width: 400px;
                height: 100px;

                transform: translateY(55%) translateX(-231px) skew(-10deg) rotate(-10deg);
            }

            transform-style: preserve-3D;
        }
        &__red {
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;

            height: 100%;
            .rectangle__item {
                position: absolute;
                bottom: 0;

                width: 273px;
                height: 50px;

                transform: translateX(-100%);

                opacity: 0.8;
                background: $red;

                @include media-breakpoint-up(lg) {
                    width: 400px;
                    height: 109px;
                }  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0% 100%);
            }
        }
    }
    .copyrights {
        display: flex;

        flex-wrap: wrap;
        li {
            margin-right: 8px;
        }
    }
}
.section__socials {
    display: flex;

    margin: 0;
    padding: 0;

    list-style: none;
    li a {
        font-size: 28px;

        display: flex;

        width: 40px;
        height: 40px;

        text-decoration: none;

        color: white;
        border-radius: 50%;
        background: $blue;

        align-items: center;
        justify-content: center;
    }
    li + li {
        margin-left: 20px;
    }
}
