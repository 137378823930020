// !: GD Component – No Javascript

.no-script.modal-backdrop.fade.in {
    position: absolute;
    z-index: 1100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0.5;
    background: rgba(black, 1);
}

.modal.fade.in.no-js {
    position: fixed;
    z-index: 1109;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;

    display: block;

    padding: 20px;

    text-align: center;

    background: #101820;
}
.modal.fade.in.no-js .noscriptmsg {
    font-size: 1.25em;

    margin-bottom: 20px;

    color: #FFFFFF;
}

input.modal-closing-trick {
    position: absolute;
    z-index: 1110;
    top: -9999px;
    left: -9999px;

    visibility: hidden;
}
input.modal-closing-trick:checked ~ div {
    display: none !important;
}
