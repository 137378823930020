.section--component-13 {
    position: relative;
    z-index: 2;

    .rectangle {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;

        content: "";

        &__item {
            position: absolute;
            right: 0;
            bottom: 0;

            display: block;
            overflow: hidden;

            width: 90px;
            height: 50px;

            transform: skew(0, -13deg) translateY(50%) ;
            // transform: skew(0, -13deg) translateY(0%) ;

            background: rgba($blue, 0.8);

            @include media-breakpoint-up(lg) {
                width: 210px;
                height: 170px;
            }
        }
    }
}
.component-13 {
    position: relative;
    z-index: 2;

    padding: 100px 0;
    &__cta {
        display: block;

        @include media-breakpoint-up(lg) {
            max-width: 420px;
            margin-left: auto;
        }
        .btn {
            display: flex;

            width: 100%;
            margin-bottom: 15px;

            @include media-breakpoint-up(lg) {
                line-height: 36px;
            }

            align-items: center;
            justify-content: center;
            svg {
                margin-top: 3px;
                margin-left: 30px;
            }
        }
        .collapse {
            &__wrapper {
                padding: 10px 20px 35px;
                p {
                    margin-bottom: 5px;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
        }
    }
    &__item {
        display: flex;

        margin-bottom: 24px;

        border-bottom: 1px dashed rgba(black,0.4);

        align-items: flex-start;
    }
    &__itemTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 140%;

        display: block;

        margin-bottom: 8px;

        text-transform: uppercase;

        color: black;
    }
    &__itemText {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 16px;

        color: black;
    }
    &__text {
        margin-left: 15px;
        margin-bottom: 0;
    }
}
