.section--component-18 {
    position: relative;
    z-index: 2;
}

.component-18 {
    padding: 100px 0;
    &__results {
        display: block;

        @include media-breakpoint-up(sm) {
            margin-top: 50px;
        }
    }
    &__container {
        max-width: 1250px;
        margin-right: auto;
        margin-left: auto;
        .component-10__details {
            padding-bottom: 0;

            border-bottom: none;
        }
    }
    &__linkTitle {
        font-size: 28px;
        font-weight: 700;
        line-height: 150%;

        display: block;

        margin-bottom: 24px;

        text-decoration: none;

        color: $navy;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
            font-weight: 600;
            line-height: 120%;

            letter-spacing: -0.01em;
        }
    }
    &__heroTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 4px;

        text-transform: uppercase;

        color: $dark-grey;
    }

    &__content {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 24px;

        color: $dark-grey;
    }
    &__details {
        display: flex;

        margin-bottom: 43px;
        // padding-bottom: 29px;
        // border-bottom: solid 1px rgba(black,0.15);

        flex-wrap: wrap;
        align-items: center;
        span {
            display: flex;

            margin-right: 34px;

            color: $dark-grey;

            @include media-breakpoint-up(lg) {
                margin-right: 40px;
            }

            align-items: center;
            img,
            svg {
                margin-right: 8px;
            }
        }
    }
    &__newsItem {
        margin-bottom: 40px;
        padding-bottom: 40px;

        border-bottom: solid 1px rgba(black,0.15);
        &:last-of-type {
            border-bottom: none;
        }
    }
    &__newsPic {
        display: block;

        @include media-breakpoint-up(lg) {
            width: 450px;
        }
        @include media-breakpoint-only(md) {
            width: 300px;
        }
        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
}
