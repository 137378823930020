.section--component-9 {
    position: relative;
    z-index: 2;

    transform: skew(0deg, -10deg);

    background: $blue;
}

.component-9 {
    position: relative;

    margin-bottom: -200px;
    padding-top: 10%;
    padding-bottom: 200px;

    transform: skew(0deg, 10deg);


    // &:before {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: auto;

    //     display: block;

    //     width: 1920px;
    //     height: 100%;

    //     content: "";


    //     background-image: url("../images/content/component-9/fig.png");
    // }
    // &:after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     left: auto;

    //     display: block;

    //     width: 1920px;
    //     height: 100%;

    //     content: "";

    //     background-color: $blue;

    //     clip-path: polygon(0 77%, 100% 0, 100% 100%, 0% 100%);
    // }
    &__wrapper {
        position: relative;
        z-index: 1;
    }

    &__content {
        color: white;
    }

    .section__title {
        font-size: 42px;
        line-height: 120%;

        margin-bottom: 50px;
        padding-top: 100px;

        text-align: center;

        color: inherit;
    }

    .section__socials {
        padding-bottom: 150px;

        justify-content: center;

        li a {
            font-size: 38px;
        }
    }
}