// !: INIT Component – Hamburger
// ::: From : https://github.com/jonsuh/hamburgers

// !: Settings
$hamburger-padding-x: 10px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 26px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 8px !default;
$hamburger-layer-color: white !default;
$hamburger-layer-border-radius: 100px !default;
$hamburger-hover-opacity: 1!default;
$hamburger-active-layer-color: white !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// !: To use CSS filters as the hover effect instead of opacity,
// ::: set $hamburger-hover-use-filter as true and
// ::: change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// !: Type
$hamburger-types: (spring) !default;

@import "./node_modules/hamburgers/_sass/hamburgers/base";
@import "./node_modules/hamburgers/_sass/hamburgers/types/spring";

.hamburger {
    z-index: $zindex-fixed;

    display: flex;
    flex-direction: column;

    width: 100px;
    height: $navbar-height-mobile;
    margin-top: - $navbar-padding-y;
    margin-bottom: - $navbar-padding-y;

    outline: none;

    @include media-breakpoint-down(xl) {
        // width: 100px;
        height: auto;
        margin: 0;
        margin-top: 24px;
        padding: 0;

        order: -1;
        &.is-active {
            height: 147px;
            margin-top: 0;
        }
    }

    order: 10;
    // @include media-breakpoint-up(lg) {
    //     display: none;
    // }
    align-items: center;
    justify-content: center;
    // &:hover {
    //     filter: drop-shadow(5px 5px 1px $blue);
    // }
    &.is-active {
        // background-color: transparent;
        @include media-breakpoint-down(xxl) {
            color: white;
            background: $blue;
        }
        .hamburger-label {
            color: white;
        }
        span:nth-child(2) {
            display: none;
        }
        span:nth-child(3) {
            display: inline;
        }
    }
    span:nth-child(3) {
        display: none;
    }

    &--spring {
        .hamburger-box {
            @include media-breakpoint-up(xl) {
                height: 31px;
            }
        }
        .hamburger-inner {
            top: 3px;
        }
    }
}
.hamburger-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;

    margin-top: 8px;

    text-decoration: none;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: white;

    @include media-breakpoint-up(xl) {
        margin-top: 15px;
    }
    @include media-breakpoint-down(xl) {
        font-size: 12px;
        line-height: 16px;
    }
    @at-root .navbar--image #{&} {
        color: $navy;
        background: white;
    }
    .template--cao & {
        color: white;
        background: $navy;
    }
}
.navbar--image {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $blue;
        .template--cao & {
            background-color: white;
        }
    }
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
        @include media-breakpoint-up(xxl) {
            background-color: $blue;
        }
    }
}
