.section--component-10 {
    position: relative;
    z-index: 2;
}

.component-10 {
    padding: 100px 0;
    .row {
        @include media-breakpoint-up(xl) {
            margin-right: -16px;
            margin-left: -16px;
            .dynamic-col {
                padding-right: 16px;
                padding-left: 16px;
            }
        }
    }

    &__box {
        display: flex;
        flex-direction: column;

        margin-bottom: 24px;

        text-decoration: none;

        background: white;

        flex: 1;
        @extend .mtu-shadow-soft;
    }
    &__item {
        margin: auto;
        padding: 46px;

        text-align: center;

        color: white;

        @include media-breakpoint-up(lg) {
            padding: 73px 32px;
        }
        h2 {
            font-size: 28px;
            font-weight: 600;
            line-height: 120%;

            margin-bottom: 14px;

            letter-spacing: -0.01em;

            color: inherit;

            @include media-breakpoint-up(lg) {
                font-size: 27px;
                font-weight: 500;
                line-height: 140%;

                margin-bottom: 66px;
            }
        }
    }
    &__cta {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }

    &__hero {
        position: absolute;
        z-index: 1;
        top: 0;

        max-width: 100%;
        height: 100%;
        img,
        svg {
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }

    // &__results {
    //     display: block;

    //     margin-top: 50px;
    // }
    // &__list {
    //     display: flex;

    //     margin-top: 50px;
    //     margin-right: -10px;
    //     margin-left: -10px;
    //     padding-left: 0;

    //     list-style: none;

    //     flex-wrap: wrap;
    //     li {
    //         padding-right: 10px;
    //         padding-left: 10px;

    //         // flex: 1;
    //         a {
    //             font-size: 18px;
    //             font-weight: 500;
    //             line-height: 140%;

    //             display: block;

    //             padding-bottom: 8px;

    //             text-align: center;
    //             text-decoration: none;
    //             letter-spacing: -0.01em;
    //             text-transform: uppercase;

    //             @include media-breakpoint-up(xl) {
    //                 min-width: 20px;
    //             }
    //             @include media-breakpoint-up(xxl) {
    //                 min-width: 25px;
    //             }
    //             @include media-breakpoint-up(xxxl) {
    //                 min-width: 30px;
    //             }
    //             &.active,
    //             &:hover {
    //                 padding-bottom: 5px;

    //                 border-bottom: solid 3px $blue;
    //             }
    //         }
    //     }
    // }
    // &__course {
    //     display: block;

    //     margin-bottom: 50px;
    // }
    // &__letter {
    //     font-size: 28px;
    //     font-weight: 700;
    //     line-height: 120%;

    //     display: block;

    //     margin-bottom: 25px;
    //     padding-bottom: 25px;

    //     letter-spacing: -0.01em;

    //     color: $navy;
    //     border-bottom: 10px solid $gold;

    //     @include media-breakpoint-up(xl) {
    //         font-size: 34px;
    //     }
    // }
    &__linkTitle {
        font-size: 28px;
        font-weight: 700;
        line-height: 150%;

        display: block;

        margin-bottom: 24px;

        text-decoration: none;

        color: $navy;

        @include media-breakpoint-up(lg) {
            font-size: 34px;
            font-weight: 600;
            line-height: 120%;

            letter-spacing: -0.01em;
        }
    }
    &__heroTitle {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 4px;

        text-transform: uppercase;

        color: $dark-grey;
    }

    // &__content {
    //     font-size: 16px;
    //     font-weight: 400;
    //     line-height: 140%;

    //     display: block;

    //     margin-bottom: 24px;

    //     color: $dark-grey;
    // }
    &__details {
        display: flex;

        margin-bottom: 30px;
        padding-bottom: 29px;

        border-bottom: solid 1px rgba(black,0.15);

        flex-wrap: wrap;
        align-items: center;
        span {
            display: flex;

            margin-right: 34px;

            color: $dark-grey;

            @include media-breakpoint-up(lg) {
                margin-right: 40px;
            }

            align-items: center;
            img,
            svg {
                margin-right: 8px;
            }
        }
    }
    // &__news {
    //     max-width: 1250px;
    //     margin-right: auto;
    //     margin-left: auto;
    //     .component-10__details {
    //         padding-bottom: 0;

    //         border-bottom: none;
    //     }
    // }
    // &__newsItem {
    //     margin-bottom: 40px;
    //     padding-bottom: 40px;

    //     border-bottom: solid 1px rgba(black,0.15);
    //     &:last-of-type {
    //         border-bottom: none;
    //     }
    // }
    // &__newsPic {
    //     display: block;

    //     @include media-breakpoint-up(lg) {
    //         width: 450px;
    //     }
    //     @include media-breakpoint-only(md) {
    //         width: 300px;
    //     }
    //     img {
    //         width: 100%;
    //         height: 100%;

    //         object-fit: cover;
    //     }
    // }
}
#datepicker {
    position: absolute;
    top: 0;

    opacity: 0;
}
.view-switchers {
    display: flex;

    padding: 20px 0;

    justify-content: space-between;
}
.view_period_range {
    font-size: 18px;
    font-weight: 500;

    color: $dark-grey;
}
.view_period_switchers {
    display: flex;

    margin-bottom: 50px;
    padding-bottom: 30px;

    border-bottom: solid 10px $gold;
    .prev-search-link {
        font-weight: 500;

        display: inline-flex;

        padding-right: 5px;

        text-decoration: none;

        align-items: center;
        svg {
            margin-top: 2px;
            margin-right: 5px;
        }
    }
    .next-search-link {
        font-weight: 500;

        display: inline-flex;

        padding-left: 5px;

        text-decoration: none;

        align-items: center;
        svg {
            margin-top: 2px;
            margin-left: 5px;
        }
    }
}
.table-wrap {
    width: 100%;
    padding: 20px;

    border: solid 1px $blue;
    border-radius: 0;
    box-shadow: none;
    .cal-table {
        width: 100%;
    }
    .calendar-box-header {
        th {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        #current_month {
            font-size: 14px;
            font-weight: 700;
            line-height: 140%;

            order: -1;
        }
        #next_month {
            .prev-next-link {
                display: none;
            }
            .ajax-load-link {
                text-align: center;
            }
            .fa {
                display: block;

                width: 10px;
                height: 10px;
                margin: auto;

                background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.39865 0.786102L4.53076 3.91821L1.39865 7.05032' stroke='%23333333' stroke-width='1.10737' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        #prev_month {
            .prev-next-link {
                display: none;
            }
            .ajax-load-link {
                text-align: center;
            }
            .fa {
                display: block;

                width: 10px;
                height: 10px;
                margin: auto;

                background-image: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.27567 0.786102L1.14355 3.91821L4.27567 7.05032' stroke='%23333333' stroke-width='1.10737' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
    .calendar-dayhead {
        th {
            span {
                font-size: 14px;
                font-weight: 700;
                line-height: 140%;

                color: #333333;
            }
        }
    }
    .calendar-dayrow {
        // .outperiodday {
        //     opacity: 0;
        // }
        td.fillday {
            color: #DDDDDD;
        }
        td:nth-last-child(-n+2) {
            font-weight: 600;
        }
        .periodday {
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;

            color: #666666;

            span,
            a {
                line-height: 31px;

                display: block;

                width: 31px;
                height: 31px;
                margin: auto;
            }
            &.today {
                a {
                    text-decoration: none;

                    color: white;
                    border-radius: 50%;
                    background: $blue;
                }
            }
            &.hasEvents {
                a {
                    text-decoration: none;

                    color: white;
                    border-radius: 50%;
                    background: $gold;
                }
            }
        }
    }
}
#calendar_box {
    table {
        tr {
            th,
            td {
                width: calc(100%/7);

                text-align: center;
            }
        }
    }
}
