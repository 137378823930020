// !: Elements – Pages

body {
    position: relative;

    display: flex;
    flex-direction: column;

    min-height: 100vh;

    background: white;
    // padding-top: $navbar-height-mobile;
    // @include media-breakpoint-up(lg) {
    //     padding-top: $navbar-height-desktop;
    // }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: auto;

    &.template {
        &--cao {
            background: $navy;
        }
    }
}

main {
    position: relative;
    z-index: 2;

    background-color: inherit;
    // overflow: hidden;

    contain: paint;
    flex: 1;

    &.enable-video {
        position: static;
        z-index: auto;

        contain: none;

        +footer {
            z-index: 0 !important;
        }
    }
}

.section {
    padding: 0;

    &.bg-none {
        background: transparent;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    main {
        flex: auto;
        // Fix for IE
    }
}

.container {
    @include media-breakpoint-down(md) {
        padding-right: 22px;
        padding-left: 22px;
    }

    @include media-breakpoint-between(xxl, xxxl) {
        padding-right: 3%;
        padding-left: 3%;
    }

    .section--narrow & {
        @include media-breakpoint-up(xxl) {
            max-width: calc(1244px + 24px);
            padding-right: 12px;
            padding-left: 12px;
        }
    }
}

.hidden {
    display: none;
}

a.skip-main {
    position: absolute;
    z-index: -999;
    top: auto;
    left: -999px;

    overflow: hidden;

    width: 1px;
    height: 1px;
    // display: none;

    color: white;
    background: black;
}

a.skip-main:focus,
a.skip-main:active {
    font-size: 1.2em;

    z-index: 1000000;
    top: auto;
    left: auto;

    overflow: auto;

    width: 30%;
    height: auto;
    margin: 10px 35%;
    padding: 8px;

    text-align: center;

    color: #FFFFFF;
    border: 4px solid $red;
    border-radius: 0;
    background-color: #000000;
}

.form-check-input,
.js-elements-container .form-group label:not([class]) input[type="checkbox"] {
    border-color: $blue-a11y;
}

.btn-white:focus {
    box-shadow: 0 0 0 0.25rem rgba(#F0AF00, 0.25);
}

a[type="button"] {
    appearance: none;
}

.js-elements-container {
    label {
        width: 100%;
    }
}

.js-t4form-container .js-elements-container {
    font-family: var(--bs-body-font-family);

    .form-control {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;

        height: auto;

        color: #212529;
        box-shadow: none;
    }

    .btn {
        @extend .btn;
        @extend .btn-primary;
    }

    label {
        font-weight: 400;
    }

    .js-input-preview.date-picker {
        padding-right: 40px;
    }

    .input-group-addon {
        position: absolute;
        z-index: 3;
        top: 50%;
        right: 0;

        margin-right: 15px;

        transform: translateY(-50%);

        border: none;
        border-radius: 0;
        background: none;
    }

    .bootstrap-datetimepicker-widget {
        min-width: 330px;
        padding: 30px;

        border: solid 1px $blue;
        border-radius: 0;

        .btn {
            border-radius: 0 !important;
        }

        .datepicker-days {
            border: none;
            border-radius: 0;
        }

        .table-condensed {
            a.btn {
                padding: 0;

                border: none;
                background: white;

                span {
                    background: $gold;
                }

                &:hover span {
                    background: $blue;
                }
            }

            button.btn-primary {
                padding: 10px;

                border: none;
                background: $blue;
            }
        }

        table tbody tr:nth-child(even) {
            background-color: #FFFFFF;
        }
    }

    .bootstrap-datetimepicker-widget table td.active,
    .bootstrap-datetimepicker-widget table td.active:hover {
        background-color: $gold;
    }

    .bootstrap-datetimepicker-widget table td.day {
        line-height: 31px;

        padding-right: 7px;
        padding-left: 7px;

        border-radius: 50%;
    }

    .bootstrap-datetimepicker-widget table td,
    .bootstrap-datetimepicker-widget table th {
        border: none;
        // border-radius: 25px;
    }

    .bootstrap-datetimepicker-widget table th {
        color: black;
        background: none;
    }
}