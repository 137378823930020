.section--component-20 {
    position: relative;
    z-index: 0;

    overflow: hidden;

    margin-top: -200px;
    margin-bottom: -1px;
    padding-top: 200px;

    transform: skew(0deg, -10deg);

    + .section--component-6,
    + span + .section--component-6 {
        margin-top: -100px;
    }
    + .section--component-7,
    + span + .section--component-7 {
        margin-top: -100px;
    }
    + .section--component-23,
    + span + .section--component-23 {
        padding-top: 0;
    }
    .template--cao & {
        + .section--component-4 {
            margin-top: 10%;
        }
    }
}

.component-20 {
    position: relative;

    padding-top: 10%;
    padding-bottom: 10%;

    transform: skew(0deg, 10deg);

    &__wrapper {
        position: relative;
        z-index: 1;
    }
    &__row {
        margin: 0 -12px 85px;

        @include media-breakpoint-up(lg) {
            margin: 0 -12px 100px;
        }
        .order-lg-last {
            .component-20__block {
                @include media-breakpoint-up(lg) {
                    margin-left: auto;
                }
            }
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__block {
        max-width: 610px;

        align-self: center;
        .btn {
            margin-bottom: 43px;
            .template--cao & {
                color: $navy;
                background-color: $gold;
            }
        }
    }
    &__quote {
        display: block;

        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }
        .template--cao & {
            color: white;
        }
    }
    &__preheading {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        display: block;

        margin-bottom: 14px;

        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            margin-bottom: 8px;
        }
        .template--cao & {
            color: white;
        }
    }
    &__heading {
        font-size: 38px;
        font-weight: 700;
        line-height: 120%;

        display: block;

        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            font-size: 42px;
        }
        .template--cao & {
            color: white;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;

        color: $grey-mid;
        .template--cao & {
            color: white;
        }
    }
    &__img {
        display: block;

        margin: 10px auto;

        img {
            width: 100%;
            max-width: 100%;
        }
    }
}
