// !: Elements – Select
$select-color-grey: #D0D0D0;
.select {
    position: relative;

    height: $input-height;

    border-radius: $base-radius;
    background: $select-color-grey;

    &:after {
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;

        width: 0;
        height: 0;
        margin: auto;

        content: "";
        pointer-events: none;

        border-width: 5px 5px 0 5px;
        border-style: solid;
        border-color: #191919 transparent transparent transparent;
    }

    &:before {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;

        width: 30px;

        content: "";
        pointer-events: none;

        background: $select-color-grey;
    }

    &:hover {
        &:before {
            background: $select-color-grey;
        }
    }

    select {
        font-size: 14px;

        width: 100%;
        height: 100%;
        padding-right: 35px;
        padding-left: 15px;

        cursor: pointer;

        border: none;
        border: 1px solid $select-color-grey;
        border-radius: 0;
        outline: none;
        background: transparent;
        box-shadow: none;
        // Disable default styling on webkit browsers

        -webkit-appearance: none;
        // Disable default styling on ff
        -moz-appearance: none;

        // Disable ugly ass outline on firefox
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000000;
        }

        // Disable default arrow on IE 11+
        &::-ms-expand {
            display: none;
        }

        &:focus {
            border-color: $select-color-grey;
        }
    }
}

// IE 9 only
@media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    .select {
        select {
            padding-right: 0;
        }

        &:after,
        &:before {
            display: none;
        }
    }
}
