// !: Elements – Input Checkboxes


.form-check-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;

    color: #555555;
}
