.section--component-14 {
    position: relative;
    z-index: 2;

    margin-bottom: -1px;

    border-top: solid 120px transparent;

    + .section--component-23,
    + span + .section--component-23 {
        padding-top: 0;
    }
    // background: $red;
}
.component-14 {
    border-top: solid 1px transparent;
    background: white;
    .component-15 .container {
        padding: 0;
    }
    &__nav {
        margin-top: -140px;

        color: black;
        background: $gold;
    }
    &__list {
        display: flex;

        margin-right: -10px;
        margin-left: -10px;

        list-style: none;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        flex-wrap: wrap;
        justify-content: space-around;
        li {
            display: flex;

            text-align: center;

            justify-content: center;
            align-items: center;
        }
        a {
            font-size: 14px;
            font-weight: 600;
            line-height: 140%;

            display: block;

            padding-top: 26px;
            padding-bottom: 23px;

            text-decoration: none;
            letter-spacing: 1px;
            text-transform: uppercase;

            color: black;
            border-bottom: solid 3px transparent;

            @include media-breakpoint-up(xl) {
                font-size: 16px;
            }
            @include media-breakpoint-up(xxl) {
                font-size: 18px;
            }
            &:hover {
                border-bottom-color: darken($gold, 15%);
            }
        }
    }
    &__item {
        &:last-of-type {
            border-bottom: none;
        }
    }
    ul:not([class]) {
        margin-bottom: 22px;
        padding-left: 32px;

        list-style: none;

        li {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;

            position: relative;

            margin-bottom: 8px;

            color: #333333;
            &::before {
                line-height: 140%;

                position: absolute;
                top: 8px;
                left: -16px;

                width: 8px;
                height: 8px;

                content: "";

                border-radius: 50%;
                background: $blue;
            }
            a {
                text-decoration: underline;

                color: inherit;

                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                text-decoration-color: $blue;
            }
        }
    }
}
.component-14,
.component-15 {
    &__item {
        padding-top: 100px;
        padding-bottom: 100px;

        border-bottom: solid 10px $gold;
    }
    &__head {
        font-size: 38px;
        font-weight: 700;
        line-height: 120%;

        color: $navy;

        @include media-breakpoint-up(lg) {
            font-size: 42px;
        }
    }
}
